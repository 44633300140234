<template>
  <div>
    <b-row>
      <b-col cols="8">
        <b-form-group label="Clave de permiso SCT" label-for="PermSCT">
          <validation-provider
            #default="{ errors }"
            name="Clave de permiso SCT"
            :vid="'PermSCT_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="PermSCT"
              v-model="transporte['@PermSCT']"
              label="title"
              catalogo="catTipoPermiso"
              clave="clave"
              descripcion="descripcion"
              :value-as-object="false"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Número de permiso SCT" label-for="NumPermisoSCT">
          <validation-provider
            #default="{ errors }"
            name="Número de permiso SCT"
            :vid="'NumPermisoSCT_' + index"
            :rules="{
              required: true,
            }"
          >
            <b-form-input
              id="NumPermisoSCT"
              v-model="transporte['@NumPermisoSCT']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="50"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Configuración vehicular"
          label-for="ConfigVehicular"
        >
          <validation-provider
            #default="{ errors }"
            name="Configuración vehicular"
            :vid="'ConfigVehicular_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="ConfigVehicular"
              v-model="transporte.IdentificacionVehicular['@ConfigVehicular']"
              label="title"
              catalogo="catConfigAutotransporte"
              clave="clave"
              descripcion="descripcion"
              :value-as-object="false"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Peso bruto vehicular (toneladas)"
          label-for="PesoBrutoVehicular"
        >
          <validation-provider
            #default="{ errors }"
            name="Peso bruto vehicular"
            :vid="'PesoBrutoVehicular_' + index"
            :rules="{
              required: true,
            }"
          >
            <cleave
              id="PesoBrutoVehicular"
              v-model="
                transporte.IdentificacionVehicular['@PesoBrutoVehicular']
              "
              class="form-control"
              placeholder="0"
              :options="cleave_options.number_with_comma_2d"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Placa vehicular" label-for="PlacaVM">
          <validation-provider
            #default="{ errors }"
            name="Placa vehicular"
            :vid="'PlacaVM_' + index"
            :rules="{
              regex: regexPlacaVM,
              required: true,
            }"
          >
            <b-form-input
              id="PlacaVM"
              v-model="transporte.IdentificacionVehicular['@PlacaVM']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="7"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Año del vehículo" label-for="AnioModeloVM">
          <validation-provider
            #default="{ errors }"
            name="Año del vehículo"
            :vid="'AnioModeloVM_' + index"
            :rules="{
              regex: regexAnioModeloVM,
              required: true,
            }"
          >
            <b-form-input
              id="PlacaVM"
              v-model="transporte.IdentificacionVehicular['@AnioModeloVM']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="4"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <b-form-group
          label="Nombre de aseguradora para responsabilidad civil"
          label-for="AseguraRespCivil"
        >
          <validation-provider
            #default="{ errors }"
            name="Nombre de aseguradora para responsabilidad civil"
            :vid="'AseguraRespCivil_' + index"
            rules="required|min:3"
          >
            <b-form-input
              id="AseguraRespCivil"
              v-model="transporte.Seguros['@AseguraRespCivil']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="50"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Número de póliza" label-for="PolizaRespCivil">
          <validation-provider
            #default="{ errors }"
            name="Número de póliza"
            :vid="'PolizaRespCivil_' + index"
            rules="required|min:3"
          >
            <b-form-input
              id="PolizaRespCivil"
              v-model="transporte.Seguros['@PolizaRespCivil']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="30"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="mostrarAseguradoraMedioAmbiente()">
      <b-col cols="5">
        <b-form-group
          label="Nombre de aseguradora para medio ambiente"
          label-for="AseguraMedAmbiente"
        >
          <validation-provider
            #default="{ errors }"
            name="Nombre de aseguradora para medio ambiente"
            :vid="'AseguraMedAmbiente_' + index"
            rules="required|min:3"
          >
            <b-form-input
              id="AseguraMedAmbiente"
              v-model="transporte.Seguros['@AseguraMedAmbiente']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="50"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Número de póliza" label-for="PolizaMedAmbiente">
          <validation-provider
            #default="{ errors }"
            name="Número de póliza"
            :vid="'PolizaMedAmbiente_' + index"
            rules="required|min:3"
          >
            <b-form-input
              id="PolizaMedAmbiente"
              v-model="transporte.Seguros['@PolizaMedAmbiente']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="30"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <b-form-group
          label="Nombre de aseguradora para la carga"
          label-for="AseguraCarga"
        >
          <validation-provider
            #default="{ errors }"
            name="Nombre de aseguradora para la carga"
            :vid="'AseguraCarga_' + index"
            rules="min:3"
          >
            <b-form-input
              id="AseguraCarga"
              v-model="transporte.Seguros['@AseguraCarga']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="50"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Número de póliza" label-for="PolizaCarga">
          <validation-provider
            #default="{ errors }"
            name="Número de póliza"
            :vid="'PolizaCarga_' + index"
            rules="min:3"
          >
            <b-form-input
              id="PolizaCarga"
              v-model="transporte.Seguros['@PolizaCarga']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="30"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-form-group
          label="Prima de seguro (convenido entre transportista y cliente)"
          label-for="PrimaSeguro"
        >
          <cleave
            id="PrimaSeguro"
            v-model="transporte.Seguros['@PrimaSeguro']"
            class="form-control"
            placeholder=""
            :options="cleave_options.number_with_comma"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <Remolques
          v-if="mostrarRemolques()"
          :remolques="transporte.Remolques.Remolque"
          :min="min_remolques"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol, BFormInput } from 'bootstrap-vue'
import { clearValuesOnHide } from '@/mixins/clearValuesOnHide'
import { validationRules } from '@/mixins/validationRules'
import AjaxVueSelect from '@/components/AjaxVueSelect'
import Remolques from '@/views/CartaPorte/Remolques'
import { ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import { cleaveOptions } from '@/mixins/cleaveOptions'
import { mapGetters } from 'vuex'

export default {
  components: {
    Remolques,
    AjaxVueSelect,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
    Cleave,
  },
  mixins: [clearValuesOnHide, validationRules, cleaveOptions],
  props: {
    transporte: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      remolque_no: [
        'C2',
        'C3',
        'OTROSG',
        'GPLUTA',
        'GPLUTB',
        'GPLUTC',
        'GPLUTD',
        'GPLATA',
        'GPLATB',
        'GPLATC',
        'GPLATD',
      ],
      remolque_opcional: ['VL', 'OTROEVGP'],
      min_remolques: 0,
    }
  },
  computed: {
    ...mapGetters('CartaPorte', ['existenMaterialesPeligrosos']),
  },
  methods: {
    mostrarRemolques() {
      if (
        this.remolque_no.includes(
          this.transporte.IdentificacionVehicular['@ConfigVehicular'].value
        )
      ) {
        if (this.transporte.Remolques.Remolque.length > 0) {
          this.transporte.Remolques.Remolque = []
        }
        return false
      } else if (
        this.remolque_opcional.includes(
          this.transporte.IdentificacionVehicular['@ConfigVehicular'].value
        )
      ) {
        this.min_remolques = 0
        return true
      } else {
        this.min_remolques = 1
        return true
      }
    },
    mostrarAseguradoraMedioAmbiente() {
      if (this.existenMaterialesPeligrosos) {
        return true
      } else {
        this.transporte.Seguros['@AseguraMedAmbiente'] = ''
        this.transporte.Seguros['@PolizaMedAmbiente'] = ''
        return false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
