<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-form-group label="Bienes transportados" label-for="BienesTransp">
          <validation-provider
            #default="{ errors }"
            name="Bienes transportados"
            :vid="'BienesTransp_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="BienesTransp"
              v-model="BienesTransp"
              label="title"
              catalogo="catClaveProdServCP"
              clave="clave"
              descripcion="descripcion"
              :value-as-object="true"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Descripcion" label-for="Descripcion">
          <validation-provider
            #default="{ errors }"
            name="Descripción"
            :vid="'Descripcion_' + index"
            :rules="{
              required: true,
            }"
          >
            <b-form-input
              id="Descripcion"
              v-model="mercancia['@Descripcion']"
              placeholder=""
              maxlength="1000"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="mostrar['@ClaveSTCC']" cols="5">
        <b-form-group label="Clave STCC" label-for="ClaveSTCC">
          <AjaxVueSelect
            id="ClaveSTCC"
            v-model="mercancia['@ClaveSTCC']"
            label="title"
            catalogo="catClaveProdSTCC"
            clave="claveSTCC"
            descripcion="descripcion"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Clave unidad" label-for="ClaveUnidad">
          <validation-provider
            #default="{ errors }"
            name="Clave unidad"
            :vid="'ClaveUnidad_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="ClaveUnidad"
              v-model="ClaveUnidad"
              label="title"
              catalogo="catClaveUnidad"
              clave="claveUnidad"
              descripcion="nombre"
              :value-as-object="true"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Unidad" label-for="Unidad">
          <b-form-input
            id="Unidad"
            v-model="mercancia['@Unidad']"
            placeholder=""
            maxlength="20"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-form-group label="Cantidad" label-for="Cantidad">
          <validation-provider
            #default="{ errors }"
            name="Cantidad"
            :vid="'Cantidad_' + index"
            :rules="{
              required: true,
            }"
          >
            <cleave
              id="Cantidad"
              v-model="mercancia['@Cantidad']"
              class="form-control"
              placeholder="0"
              :options="cleave_options.number_with_comma"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Dimensiones" label-for="Dimensiones">
          <validation-provider
            #default="{ errors }"
            name="Dimensiones"
            :vid="'Dimensiones_' + index"
            :rules="{
              regex: regexDimensiones,
            }"
          >
            <b-form-input
              id="Dimensiones"
              v-model="mercancia['@Dimensiones']"
              placeholder="144/89/55cm"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Peso en kg" label-for="PesoEnKg">
          <validation-provider
            #default="{ errors }"
            name="Peso en kg"
            :vid="'PesoEnKg_' + index"
            :rules="{
              required: true,
            }"
          >
            <cleave
              id="PesoEnKg"
              v-model="mercancia['@PesoEnKg']"
              class="form-control"
              placeholder="0"
              :options="cleave_options.number_with_comma_3d"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Valor de la mercancia" label-for="ValorMercancia">
          <validation-provider
            #default="{ errors }"
            name="Valor de la mercancia"
            :vid="'ValorMercancia_' + index"
            :rules="{
              required: true,
            }"
          >
            <cleave
              id="ValorMercancia"
              v-model="mercancia['@ValorMercancia']"
              class="form-control"
              placeholder="0"
              :options="cleave_options.number_with_comma"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Moneda" label-for="Moneda">
          <validation-provider
            #default="{ errors }"
            name="Moneda"
            :vid="'Moneda_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="Moneda"
              v-model="mercancia['@Moneda']"
              label="title"
              catalogo="catMoneda"
              clave="moneda"
              descripcion="descripcion"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="mostrar['@TipoMateria']" cols="4">
        <b-form-group label="Tipo materia" label-for="TipoMateria">
          <validation-provider
            #default="{ errors }"
            name="Tipo materia"
            :vid="'TipoMateria_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="TipoMateria"
              v-model="mercancia['@TipoMateria']"
              label="title"
              catalogo="catTipoMateria"
              clave="clave"
              descripcion="descripcion"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@DescripcionMateria']" cols="4">
        <b-form-group
          label="Descripción de materia"
          label-for="DescripcionMateria"
        >
          <validation-provider
            #default="{ errors }"
            name="Descripción de materia"
            :vid="'DescripcionMateria_' + index"
            :rules="{
              required: true,
            }"
          >
            <b-form-input
              id="DescripcionMateria"
              v-model="mercancia['@DescripcionMateria']"
              placeholder=""
              maxlength="50"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="mostrar['@MaterialPeligroso']" cols="2">
        <b-form-group label="Material peligroso" label-for="MaterialPeligroso">
          <validation-provider
            #default="{ errors }"
            name="Material peligroso"
            :vid="'MaterialPeligroso_' + index"
            :rules="{
              required: true,
            }"
          >
            <v-select
              id="MaterialPeligroso"
              v-model="mercancia['@MaterialPeligroso']"
              label="title"
              :options="MaterialPeligroso_options"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-show="mostrar['@CveMaterialPeligroso']" cols="4">
        <b-form-group
          label="Clave material peligroso"
          label-for="CveMaterialPeligroso"
        >
          <AjaxVueSelect
            id="CveMaterialPeligroso"
            v-model="mercancia['@CveMaterialPeligroso']"
            label="title"
            catalogo="catMaterialPeligroso"
            clave="claveMaterial"
            descripcion="descripcion"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col v-show="mostrar['@Embalaje']" cols="3">
        <b-form-group label="Embalaje" label-for="Embalaje">
          <AjaxVueSelect
            id="Embalaje"
            v-model="Embalaje"
            label="title"
            catalogo="catTipoEmbalaje"
            clave="clave"
            descripcion="descripcion"
            :value-as-object="true"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col v-show="mostrar['@DescripEmbalaje']" cols="3">
        <b-form-group label="Descripción embalaje" label-for="DescripEmbalaje">
          <b-form-input
            id="DescripEmbalaje"
            v-model="mercancia['@DescripEmbalaje']"
            placeholder=""
            maxlength="100"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="mostrar['@FraccionArancelaria']" cols="8">
        <b-form-group
          label="Fraccion arancelaria"
          label-for="FraccionArancelaria"
        >
          <validation-provider
            #default="{ errors }"
            name="Fraccion arancelaria"
            :vid="'FraccionArancelaria_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="FraccionArancelaria"
              v-model="mercancia['@FraccionArancelaria']"
              label="title"
              catalogo="catFraccionArancelaria"
              clave="fraccionArancelaria"
              descripcion="descripcion"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@UUIDComercioExt']" cols="4">
        <b-form-group
          label="UUID comercio exterior"
          label-for="UUIDComercioExt"
        >
          <validation-provider
            #default="{ errors }"
            name="UUID comercio exterior"
            :vid="'UUIDComercioExt_' + index"
            :rules="{
              regex: regexUUID,
            }"
          >
            <b-form-input
              id="UUIDComercioExt"
              v-model="mercancia['@UUIDComercioExt']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <DocumentacionesAduaneras
          v-if="mostrarDocumentacionesAduaneras()"
          :documentaciones-aduaneras="mercancia.DocumentacionAduanera"
        />
        <hr v-if="mostrarDocumentacionesAduaneras()" />
        <CantidadesTransportadas
          v-if="mostrarCantidadesTransportadas()"
          :cantidades="mercancia.CantidadTransporta"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <b-form-group label="Aplica sector COFEPRIS" label-for="AplicaCOFEPRIS">
          <v-select
            id="AplicaCOFEPRIS"
            v-model="AplicaCOFEPRIS"
            label="title"
            :options="AplicaSectorCOFEPRIS_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="mostrar['@SectorCOFEPRIS']" cols="4">
        <b-form-group label="Sector COFEPRIS" label-for="SectorCOFEPRIS">
          <AjaxVueSelect
            id="SectorCOFEPRIS"
            v-model="mercancia['@SectorCOFEPRIS']"
            label="title"
            catalogo="catSectorCOFEPRIS"
            clave="clave"
            descripcion="descripcion"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@NombreIngredienteActivo']" cols="4">
        <b-form-group
          label="Nombre ingrediente activo"
          label-for="NombreIngredienteActivo"
        >
          <b-form-input
            id="NombreIngredienteActivo"
            v-model="mercancia['@NombreIngredienteActivo']"
            placeholder=""
            maxlength="1000"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@NomQuimico']" cols="4">
        <b-form-group label="Nombre químico" label-for="NomQuimico">
          <b-form-input
            id="NomQuimico"
            v-model="mercancia['@NomQuimico']"
            placeholder=""
            maxlength="150"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@DenominacionGenericaProd']" cols="4">
        <b-form-group
          label="Denominación genérica de producto"
          label-for="DenominacionGenericaProd"
        >
          <b-form-input
            id="DenominacionGenericaProd"
            v-model="mercancia['@DenominacionGenericaProd']"
            placeholder=""
            maxlength="50"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@DenominacionDistintivaProd']" cols="4">
        <b-form-group
          label="Denominación distintiva de producto"
          label-for="DenominacionDistintivaProd"
        >
          <b-form-input
            id="DenominacionDistintivaProd"
            v-model="mercancia['@DenominacionDistintivaProd']"
            placeholder=""
            maxlength="50"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@Fabricante']" cols="4">
        <b-form-group label="Fabricante" label-for="Fabricante">
          <b-form-input
            id="Fabricante"
            v-model="mercancia['@Fabricante']"
            placeholder=""
            maxlength="240"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@FechaCaducidad']" cols="4">
        <b-form-group label="Fecha de caducidad" label-for="FechaCaducidad">
          <flat-pickr
            id="FechaCaducidad"
            v-model="mercancia['@FechaCaducidad']"
            class="form-control"
            :config="flatPickrAAAAMMDDConfig"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@LoteMedicamento']" cols="4">
        <b-form-group label="Lote de medicamento" label-for="LoteMedicamento">
          <b-form-input
            id="LoteMedicamento"
            v-model="mercancia['@LoteMedicamento']"
            placeholder=""
            maxlength="10"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@FormaFarmaceutica']" cols="4">
        <b-form-group label="Forma farmaceutica" label-for="FormaFarmaceutica">
          <AjaxVueSelect
            id="FormaFarmaceutica"
            v-model="mercancia['@FormaFarmaceutica']"
            label="title"
            catalogo="catFormaFarmaceutica"
            clave="clave"
            descripcion="descripcion"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@CondicionesEspTransp']" cols="4">
        <b-form-group
          label="Condiciones especiales de transporte"
          label-for="CondicionesEspTransp"
        >
          <AjaxVueSelect
            id="CondicionesEspTransp"
            v-model="mercancia['@CondicionesEspTransp']"
            label="title"
            catalogo="catCondicionesEspeciales"
            clave="clave"
            descripcion="descripcion"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@RegistroSanitarioFolioAutorizacion']" cols="4">
        <b-form-group
          label="Registro sanitario o folio de autorizacion"
          label-for="RegistroSanitarioFolioAutorizacion"
        >
          <b-form-input
            id="RegistroSanitarioFolioAutorizacion"
            v-model="mercancia['@RegistroSanitarioFolioAutorizacion']"
            placeholder=""
            maxlength="15"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@PermisoImportacion']" cols="4">
        <b-form-group
          label="Permiso de importación"
          label-for="PermisoImportacion"
        >
          <b-form-input
            id="PermisoImportacion"
            v-model="mercancia['@PermisoImportacion']"
            placeholder=""
            maxlength="6"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@FolioImpoVUCEM']" cols="4">
        <b-form-group
          label="Folio de importación VUCEM"
          label-for="FolioImpoVUCEM"
        >
          <b-form-input
            id="FolioImpoVUCEM"
            v-model="mercancia['@FolioImpoVUCEM']"
            placeholder=""
            maxlength="25"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@NumCAS']" cols="4">
        <b-form-group label="Número CAS" label-for="NumCAS">
          <b-form-input
            id="NumCAS"
            v-model="mercancia['@NumCAS']"
            placeholder=""
            maxlength="15"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@RazonSocialEmpImp']" cols="4">
        <b-form-group
          label="Razón social empresa importadora"
          label-for="RazonSocialEmpImp"
        >
          <b-form-input
            id="RazonSocialEmpImp"
            v-model="mercancia['@RazonSocialEmpImp']"
            placeholder=""
            maxlength="80"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@NumRegSanPlagCOFEPRIS']" cols="4">
        <b-form-group
          label="Número registro sanitario COFEPRIS"
          label-for="NumRegSanPlagCOFEPRIS"
        >
          <b-form-input
            id="NumRegSanPlagCOFEPRIS"
            v-model="mercancia['@NumRegSanPlagCOFEPRIS']"
            placeholder=""
            maxlength="60"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@DatosFabricante']" cols="4">
        <b-form-group label="Datos del fabricante" label-for="DatosFabricante">
          <b-form-input
            id="DatosFabricante"
            v-model="mercancia['@DatosFabricante']"
            placeholder=""
            maxlength="600"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@DatosFormulador']" cols="4">
        <b-form-group label="Datos formulador" label-for="DatosFormulador">
          <b-form-input
            id="DatosFormulador"
            v-model="mercancia['@DatosFormulador']"
            placeholder=""
            maxlength="600"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@DatosMaquilador']" cols="4">
        <b-form-group label="Datos maquilador" label-for="DatosMaquilador">
          <b-form-input
            id="DatosMaquilador"
            v-model="mercancia['@DatosMaquilador']"
            placeholder=""
            maxlength="600"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@UsoAutorizado']" cols="4">
        <b-form-group label="Uso autorizado" label-for="UsoAutorizado">
          <b-form-input
            id="UsoAutorizado"
            v-model="mercancia['@UsoAutorizado']"
            placeholder=""
            maxlength="1000"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol, BFormInput } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import AjaxVueSelect from '@/components/AjaxVueSelect'
import vSelect from 'vue-select'
import { cleaveOptions } from '@/mixins/cleaveOptions'
import { validationRules } from '@/mixins/validationRules'
import { mapFields } from 'vuex-map-fields'
import { clearValuesOnHide } from '@/mixins/clearValuesOnHide'
import { mapGetters } from 'vuex'
import CantidadesTransportadas from '@/views/CartaPorte/CantidadesTransportadas'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import DocumentacionesAduaneras from '@/views/CartaPorte/DocumentacionesAduaneras'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/l10n/es'
import { flatPickrAAAAMMDDConfig } from '@/mixins/flatPickrAAAAMMDDConfig'

export default {
  components: {
    DocumentacionesAduaneras,
    CantidadesTransportadas,
    AjaxVueSelect,
    BFormGroup,
    BFormInput,
    Cleave,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    flatPickr,
  },
  mixins: [
    cleaveOptions,
    validationRules,
    clearValuesOnHide,
    flatPickrAAAAMMDDConfig,
  ],
  props: {
    mercancia: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    if (this.mercancia['@SectorCOFEPRIS'].value) {
      return {
        BienesTransp: '',
        ClaveUnidad: '',
        Embalaje: '',
        MaterialPeligroso: '',
        MaterialPeligroso_options: [
          { title: 'Sí', value: 'Sí' },
          { title: 'No', value: 'No' },
        ],
        AplicaCOFEPRIS: { title: 'Sí', value: 'Sí' },
        AplicaSectorCOFEPRIS_options: [
          { title: 'Sí', value: 'Sí' },
          { title: 'No', value: 'No' },
        ],
      }
    } else {
      return {
        BienesTransp: '',
        ClaveUnidad: '',
        Embalaje: '',
        MaterialPeligroso: '',
        MaterialPeligroso_options: [
          { title: 'Sí', value: 'Sí' },
          { title: 'No', value: 'No' },
        ],
        AplicaCOFEPRIS: '',
        AplicaSectorCOFEPRIS_options: [
          { title: 'Sí', value: 'Sí' },
          { title: 'No', value: 'No' },
        ],
      }
    }
  },
  computed: {
    ...mapFields('CartaPorte', {
      TranspInternac: 'objJson.complemento.CartaPorte.@TranspInternac',
      EntradaSalidaMerc: 'objJson.complemento.CartaPorte.@EntradaSalidaMerc',
    }),
    ...mapGetters('CartaPorte', {
      tiposDeTransporte: 'getTransportesUtilizados',
      getTiposDeUbicaciones: 'getTiposDeUbicaciones',
    }),
    mostrar() {
      // Limpia valores referentes a materiales peligrosos
      let campos = {
        '@MaterialPeligroso': ['0,1', '1'].includes(this.MaterialPeligroso),
        '@FraccionArancelaria': ['Sí'].includes(this.TranspInternac.value),
        '@UUIDComercioExt': ['Sí'].includes(this.TranspInternac.value),
        '@ClaveSTCC': this.tiposDeTransporte.includes('04'),
        // COFEPRIS
        '@SectorCOFEPRIS': this.AplicaCOFEPRIS.value === 'Sí',
        '@NombreIngredienteActivo':
          ['02', '05'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@NomQuimico':
          ['02', '04'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@DenominacionGenericaProd':
          ['01', '03'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@DenominacionDistintivaProd':
          ['01', '03'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@Fabricante':
          ['01', '02', '03'].includes(
            this.mercancia['@SectorCOFEPRIS'].value
          ) && this.AplicaCOFEPRIS.value === 'Sí',
        '@FechaCaducidad':
          ['01', '02', '03'].includes(
            this.mercancia['@SectorCOFEPRIS'].value
          ) && this.AplicaCOFEPRIS.value === 'Sí',
        '@LoteMedicamento':
          ['01', '02', '03'].includes(
            this.mercancia['@SectorCOFEPRIS'].value
          ) && this.AplicaCOFEPRIS.value === 'Sí',
        '@FormaFarmaceutica':
          ['01', '02', '03'].includes(
            this.mercancia['@SectorCOFEPRIS'].value
          ) && this.AplicaCOFEPRIS.value === 'Sí',
        '@CondicionesEspTransp':
          ['01', '02', '03'].includes(
            this.mercancia['@SectorCOFEPRIS'].value
          ) && this.AplicaCOFEPRIS.value === 'Sí',
        '@RegistroSanitarioFolioAutorizacion':
          ['01', '03'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@NumCAS':
          ['04'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@NumRegSanPlagCOFEPRIS':
          ['05'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@DatosFabricante':
          ['05'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@DatosFormulador':
          ['05'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@DatosMaquilador':
          ['05'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@UsoAutorizado':
          ['05'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@TipoMateria': ['Sí'].includes(this.TranspInternac.value),
        '@DescripcionMateria':
          ['Sí'].includes(this.TranspInternac.value) &&
          ['05'].includes(this.mercancia['@TipoMateria'].value),
        '@PermisoImportacion':
          ['Sí'].includes(this.TranspInternac.value) &&
          ['Entrada'].includes(this.EntradaSalidaMerc.value) &&
          ['01', '02', '03'].includes(
            this.mercancia['@SectorCOFEPRIS'].value
          ) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@FolioImpoVUCEM':
          ['Sí'].includes(this.TranspInternac.value) &&
          ['Entrada'].includes(this.EntradaSalidaMerc.value) &&
          ['01', '02', '04', '05'].includes(
            this.mercancia['@SectorCOFEPRIS'].value
          ) &&
          this.AplicaCOFEPRIS.value === 'Sí',
        '@RazonSocialEmpImp':
          ['Sí'].includes(this.TranspInternac.value) &&
          ['Entrada'].includes(this.EntradaSalidaMerc.value) &&
          ['04'].includes(this.mercancia['@SectorCOFEPRIS'].value) &&
          this.AplicaCOFEPRIS.value === 'Sí',
      }

      let mostrarDetallesMaterialPeligroso =
        ['Sí'].includes(this.mercancia['@MaterialPeligroso'].value) &&
        campos['@MaterialPeligroso']

      campos['@CveMaterialPeligroso'] = mostrarDetallesMaterialPeligroso
      campos['@Embalaje'] = mostrarDetallesMaterialPeligroso
      campos['@DescripEmbalaje'] = mostrarDetallesMaterialPeligroso

      this.clearValuesOnHide(this.mercancia, campos)

      // Limia valores de campos dinamicos tipo objeto
      let variables_en_objeto = {
        Embalaje: mostrarDetallesMaterialPeligroso,
      }
      this.clearValuesOnHide(this, variables_en_objeto)

      return campos
    },
  },
  watch: {
    BienesTransp() {
      if (typeof this.BienesTransp.value === 'object') {
        this.mercancia['@BienesTransp'] = {
          title: `${this.BienesTransp.value['clave']} - ${this.BienesTransp.value['descripcion']}`,
          value: this.BienesTransp.value['clave'],
        }
        this.MaterialPeligroso = this.BienesTransp.value['peligroso']
        this.mercancia['@Descripcion'] = this.BienesTransp.value['descripcion']
        if (this.BienesTransp.value['peligroso'] === '0,1') {
          this.MaterialPeligroso_options = [
            { title: 'Sí', value: 'Sí' },
            { title: 'No', value: 'No' },
          ]
          this.mercancia['@MaterialPeligroso'] = { title: 'No', value: 'No' }
        } else if (this.BienesTransp.value['peligroso'] === '1') {
          this.MaterialPeligroso_options = [{ title: 'Sí', value: 'Sí' }]
          this.mercancia['@MaterialPeligroso'] = { title: 'Sí', value: 'Sí' }
        }
      }
    },
    ClaveUnidad() {
      if (typeof this.ClaveUnidad.value === 'object') {
        this.mercancia['@ClaveUnidad'] = {
          title: `${this.ClaveUnidad.value['claveUnidad']} - ${this.ClaveUnidad.value['nombre']}`,
          value: this.ClaveUnidad.value['claveUnidad'],
        }
        this.mercancia['@Unidad'] = this.ClaveUnidad.value['nombre'].substring(
          0,
          20
        )
      }
    },
    Embalaje() {
      if (typeof this.Embalaje.value === 'object') {
        this.mercancia['@Embalaje'] = {
          title: `${this.Embalaje.value['clave']} - ${this.Embalaje.value['descripcion']}`,
          value: this.Embalaje.value['clave'],
        }
        this.mercancia['@DescripEmbalaje'] = this.Embalaje.value['descripcion']
      }
    },
  },
  created() {
    // BienesTransp
    this.BienesTransp = this.mercancia['@BienesTransp']
    if (
      this.BienesTransp &&
      this.mercancia['@Descripcion'] === '' &&
      this.BienesTransp['title'] !== ''
    ) {
      this.mercancia['@Descripcion'] = this.BienesTransp['title']
        .split('-')[1]
        .trim()
    }
    // Material peligroso - si existe CveMaterialPeligroso establece this.MaterialPeligroso a 0,1
    // para que no se oculte al cargar
    if (
      this.mercancia['@MaterialPeligroso'] !== '' ||
      (this.mercancia['@CveMaterialPeligroso'] &&
        this.mercancia['@CveMaterialPeligroso'].value !== '')
    ) {
      this.MaterialPeligroso = '0,1'
    }

    // ClaveUnidad
    this.ClaveUnidad = this.mercancia['@ClaveUnidad']
    if (
      this.ClaveUnidad &&
      this.mercancia['@Unidad'] === '' &&
      this.ClaveUnidad['title'] !== ''
    ) {
      this.mercancia['@Unidad'] = this.ClaveUnidad['title'].split('-')[1].trim()
    }
    // Embalaje
    this.Embalaje = this.mercancia['@Embalaje']
    if (
      this.Embalaje &&
      this.mercancia['@DescripEmbalaje'] === '' &&
      this.Embalaje['title'] !== ''
    ) {
      this.mercancia['@DescripEmbalaje'] = this.Embalaje['title']
        .split('-')[1]
        .trim()
    }
  },
  methods: {
    mostrarCantidadesTransportadas() {
      if (this.getTiposDeUbicaciones.length > 2) {
        return true
      } else {
        if (this.mercancia.CantidadTransporta.length > 0) {
          this.mercancia.CantidadTransporta = []
        }
        return false
      }
    },
    mostrarDocumentacionesAduaneras() {
      if (this.TranspInternac.value === 'Sí') {
        return true
      } else {
        if (this.mercancia.DocumentacionAduanera.length > 0) {
          this.mercancia.DocumentacionAduanera = []
        }
        return false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
