<template>
  <div v-if="cantidad">
    <b-row>
      <b-col cols="3">
        <b-form-group label="Cantidad" label-for="cantidad">
          <validation-provider
            #default="{ errors }"
            name="Cantidad"
            :vid="'cantidad_' + index"
            :rules="{
              required: true,
            }"
          >
            <cleave
              id="cantidad"
              v-model="cantidad['@Cantidad']"
              class="form-control"
              placeholder="0"
              :options="cleave_options.number_with_comma"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="ID origen" label-for="IDOrigen">
          <validation-provider
            #default="{ errors }"
            name="ID origen"
            :vid="'IDOrigen_' + index"
            :rules="{
              digits: 6,
              required: true,
            }"
          >
            <cleave
              id="IDOrigen"
              v-model="idorigen"
              class="form-control"
              placeholder="012345"
              :options="cleave_options.prefix_origen"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="ID destino" label-for="IDDestino">
          <validation-provider
            #default="{ errors }"
            name="ID destino"
            :vid="'IDDestino_' + index"
            :rules="{
              digits: 6,
              required: true,
            }"
          >
            <cleave
              id="IDDestino"
              v-model="iddestino"
              class="form-control"
              placeholder="012345"
              :options="cleave_options.prefix_origen"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol } from 'bootstrap-vue'
import { clearValuesOnHide } from '@/mixins/clearValuesOnHide'
import { validationRules } from '@/mixins/validationRules'
import Cleave from 'vue-cleave-component'
import { cleaveOptions } from '@/mixins/cleaveOptions'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    Cleave,
    ValidationProvider,
  },
  mixins: [clearValuesOnHide, validationRules, cleaveOptions],
  props: {
    cantidad: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      idorigen: '',
      iddestino: '',
      TipoTransporte_options: [
        { title: '', value: '' },
        { title: 'Autotransporte', value: '01' },
        { title: 'Transporte Marítimo', value: '02' },
        { title: 'Transporte Aéreo', value: '03' },
        { title: 'Transporte Ferroviario', value: '04' },
      ],
    }
  },
  watch: {
    idorigen(newValue) {
      if (newValue !== '') {
        this.cantidad['@IDOrigen'] = 'OR' + newValue
      } else {
        this.cantidad['@IDOrigen'] = ''
      }
    },
    iddestino(newValue) {
      if (newValue !== '') {
        this.cantidad['@IDDestino'] = 'DE' + newValue
      } else {
        this.cantidad['@IDDestino'] = ''
      }
    },
  },
  created() {
    this.idorigen = this.cantidad['@IDOrigen'].substring(2)
    this.iddestino = this.cantidad['@IDDestino'].substring(2)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
