var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.tipoRemitente === 'nacional')?[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Codigo postal","label-for":"CodigoPostal"}},[_c('validation-provider',{attrs:{"name":"Codigo postal","vid":'CodigoPostal_' + _vm.index,"rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"CodigoPostal","label":"title","catalogo":"catCodigoPostal","clave":"codigoPostal","descripcion":"estado","clearable":false,"value-as-object":true},model:{value:(_vm.codigoPostal),callback:function ($$v) {_vm.codigoPostal=$$v},expression:"codigoPostal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1621678974)})],1)],1),(_vm.domicilio['@CodigoPostal'].value)?_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"Estado"}},[_c('validation-provider',{attrs:{"name":"Estado","vid":'Estado_' + _vm.index,"rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"Estado","label":"title","catalogo":"catEstado","clave":"estado","descripcion":"descripcion","clearable":false,"search-on-focus":true,"search-terms":_vm.estado},model:{value:(_vm.domicilio['@Estado']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Estado', $$v)},expression:"domicilio['@Estado']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,61964669)})],1)],1):_vm._e(),(_vm.municipio)?_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Municipio","label-for":"Municipio"}},[_c('validation-provider',{attrs:{"name":"Municipio","vid":'Municipio_' + _vm.index,"rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"Municipio","label":"title","catalogo":"catMunicipio","clave":"municipio","descripcion":"descripcion","clearable":false,"search-on-focus":true,"search-terms":_vm.municipio + '+' + _vm.estado},model:{value:(_vm.domicilio['@Municipio']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Municipio', $$v)},expression:"domicilio['@Municipio']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1977981493)})],1)],1):_vm._e(),(_vm.localidad)?_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Localidad","label-for":"Localidad"}},[_c('validation-provider',{attrs:{"name":"Localidad","vid":'Localidad_' + _vm.index,"rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"Localidad","label":"title","catalogo":"catLocalidad","clave":"localidad","descripcion":"descripcion","clearable":false,"search-on-focus":true,"search-terms":_vm.localidad + '+' + _vm.estado},model:{value:(_vm.domicilio['@Localidad']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Localidad', $$v)},expression:"domicilio['@Localidad']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1402570163)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Calle","label-for":"Calle"}},[_c('validation-provider',{attrs:{"name":"Calle","vid":'Calle_' + _vm.index,"rules":{
              max: 100,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Calle","placeholder":"","maxlength":"100"},model:{value:(_vm.domicilio['@Calle']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Calle', $$v)},expression:"domicilio['@Calle']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2771844403)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Numero exterior","label-for":"NumeroExterior"}},[_c('b-form-input',{attrs:{"id":"NumeroExterior","placeholder":""},model:{value:(_vm.domicilio['@NumeroExterior']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@NumeroExterior', $$v)},expression:"domicilio['@NumeroExterior']"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Numero interior","label-for":"NumeroInterior"}},[_c('b-form-input',{attrs:{"id":"NumeroInterior","placeholder":""},model:{value:(_vm.domicilio['@NumeroInterior']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@NumeroInterior', $$v)},expression:"domicilio['@NumeroInterior']"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Colonia","label-for":"Colonia"}},[_c('AjaxVueSelect',{attrs:{"id":"Colonia","label":"title","catalogo":"catColonia","clave":"colonia","descripcion":"descripcion","clearable":false,"search-on-focus":true,"search-terms":_vm.domicilio['@CodigoPostal']
                ? _vm.domicilio['@CodigoPostal'].value
                : ''},model:{value:(_vm.domicilio['@Colonia']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Colonia', $$v)},expression:"domicilio['@Colonia']"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Referencia","label-for":"Referencia"}},[_c('b-form-input',{attrs:{"id":"Referencia","placeholder":""},model:{value:(_vm.domicilio['@Referencia']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Referencia', $$v)},expression:"domicilio['@Referencia']"}})],1)],1)],1)]:_vm._e(),(_vm.tipoRemitente === 'extranjero')?[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Calle","label-for":"Calle"}},[_c('validation-provider',{attrs:{"name":"Calle","vid":'eCalle_' + _vm.index,"rules":{
              max: 100,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Calle","placeholder":"","maxlength":"100"},model:{value:(_vm.domicilio['@Calle']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Calle', $$v)},expression:"domicilio['@Calle']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2771844403)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Numero exterior","label-for":"NumeroExterior"}},[_c('b-form-input',{attrs:{"id":"NumeroExterior","placeholder":""},model:{value:(_vm.domicilio['@NumeroExterior']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@NumeroExterior', $$v)},expression:"domicilio['@NumeroExterior']"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Numero interior","label-for":"NumeroInterior"}},[_c('b-form-input',{attrs:{"id":"NumeroInterior","placeholder":""},model:{value:(_vm.domicilio['@NumeroInterior']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@NumeroInterior', $$v)},expression:"domicilio['@NumeroInterior']"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Colonia","label-for":"Colonia"}},[_c('b-form-input',{attrs:{"id":"Colonia","placeholder":"","maxlength":"120"},model:{value:(_vm.domicilio['@Colonia']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Colonia', $$v)},expression:"domicilio['@Colonia']"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Referencia","label-for":"Referencia"}},[_c('b-form-input',{attrs:{"id":"Referencia","placeholder":""},model:{value:(_vm.domicilio['@Referencia']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Referencia', $$v)},expression:"domicilio['@Referencia']"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Pais","label-for":"Pais"}},[_c('validation-provider',{attrs:{"name":"Pais","vid":'ePais_' + _vm.index,"rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"Pais","label":"title","catalogo":"catPais","clave":"pais","descripcion":"descripcion","clearable":false},model:{value:(_vm.pais),callback:function ($$v) {_vm.pais=$$v},expression:"pais"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2835824491)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"Estado"}},[_c('validation-provider',{attrs:{"name":"Estado","vid":'eEstado_' + _vm.index,"rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(['USA', 'CAN', 'MEX'].includes(_vm.domicilio['@Pais'].value))?_c('AjaxVueSelect',{attrs:{"id":"Estado","label":"title","catalogo":"catEstado","clave":"estado","descripcion":"descripcion","clearable":false,"search-on-focus":true,"search-terms":_vm.domicilio['@Pais'] ? _vm.domicilio['@Pais'].value : ''},model:{value:(_vm.domicilio['@Estado']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Estado', $$v)},expression:"domicilio['@Estado']"}}):_c('b-form-input',{attrs:{"id":"Estado","placeholder":"","maxlength":"30"},model:{value:(_vm.domicilio['@Estado']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Estado', $$v)},expression:"domicilio['@Estado']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1774102820)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Municipio","label-for":"Municipio"}},[_c('b-form-input',{attrs:{"id":"Municipio","placeholder":"","maxlength":"120"},model:{value:(_vm.domicilio['@Municipio']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Municipio', $$v)},expression:"domicilio['@Municipio']"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Localidad","label-for":"Localidad"}},[_c('b-form-input',{attrs:{"id":"Localidad","placeholder":"","maxlength":"120"},model:{value:(_vm.domicilio['@Localidad']),callback:function ($$v) {_vm.$set(_vm.domicilio, '@Localidad', $$v)},expression:"domicilio['@Localidad']"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Codigo Postal","label-for":"CodigoPostal"}},[_c('validation-provider',{attrs:{"name":"Codigo Postal","vid":'eCodigoPostal_' + _vm.index,"rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"CodigoPostal","placeholder":"","maxlength":"12"},model:{value:(_vm.codigoPostal),callback:function ($$v) {_vm.codigoPostal=$$v},expression:"codigoPostal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3914686484)})],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }