var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.documentacionAduanera)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo de documento aduanero","label-for":"TipoDocumento"}},[_c('validation-provider',{attrs:{"name":"Tipo de documento aduanero","vid":'TipoDocumento_' + _vm.index,"rules":{
            required: _vm.EntradaSalidaMerc.value === 'Entrada',
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"TipoDocumento","label":"title","catalogo":"catDocumentoAduanero","clave":"clave","descripcion":"descripcion","search-terms":_vm.EntradaSalidaMerc.value == 'Entrada' ? 'Entrada' : 'Salida',"clearable":true},model:{value:(_vm.documentacionAduanera['@TipoDocumento']),callback:function ($$v) {_vm.$set(_vm.documentacionAduanera, '@TipoDocumento', $$v)},expression:"documentacionAduanera['@TipoDocumento']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3956827348)})],1)],1)],1),_c('b-row',[(_vm.mostrar['@NumPedimento'])?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Pedimento","label-for":"NumPedimento"}},[_c('validation-provider',{attrs:{"name":"Pedimento","vid":'Pedimento_' + _vm.index,"rules":{
            required: true,
            regex: _vm.regexPedimento,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"NumPedimento","placeholder":"00  00  0000  0000000","options":_vm.cleave_options.pedimento,"raw":false},model:{value:(_vm.documentacionAduanera['@NumPedimento']),callback:function ($$v) {_vm.$set(_vm.documentacionAduanera, '@NumPedimento', $$v)},expression:"documentacionAduanera['@NumPedimento']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,200972508)})],1)],1):_vm._e(),(_vm.mostrar['@IdentDocAduanero'])?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Identificador del documento aduanero","label-for":"IdentDocAduanero"}},[_c('validation-provider',{attrs:{"name":"documento aduanero","vid":'IdentDocAduanero_' + _vm.index,"rules":{
            required: _vm.EntradaSalidaMerc.value === 'Entrada',
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"IdentDocAduanero","placeholder":"","maxlength":"150"},model:{value:(_vm.documentacionAduanera['@IdentDocAduanero']),callback:function ($$v) {_vm.$set(_vm.documentacionAduanera, '@IdentDocAduanero', $$v)},expression:"documentacionAduanera['@IdentDocAduanero']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1363056309)})],1)],1):_vm._e(),(_vm.mostrar['@RFCImpo'])?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"RFC importador","label-for":"RFCImpo"}},[_c('validation-provider',{attrs:{"name":"RFC importador","vid":'RFCImpo_' + _vm.index,"rules":{
            required: true,
            regex: _vm.regexRfc,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"RFCImpo","placeholder":"XAXX010101000","state":errors.length > 0 ? false : null,"maxlength":"13"},model:{value:(_vm.documentacionAduanera['@RFCImpo']),callback:function ($$v) {_vm.$set(_vm.documentacionAduanera, '@RFCImpo', $$v)},expression:"documentacionAduanera['@RFCImpo']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,412819774)})],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }