<template>
  <div>
    <template v-if="tipoRemitente === 'nacional'">
      <b-row>
        <b-col cols="3">
          <b-form-group label="Codigo postal" label-for="CodigoPostal">
            <validation-provider
              #default="{ errors }"
              name="Codigo postal"
              :vid="'CodigoPostal_' + index"
              :rules="{
                required: true,
              }"
            >
              <AjaxVueSelect
                id="CodigoPostal"
                v-model="codigoPostal"
                label="title"
                catalogo="catCodigoPostal"
                clave="codigoPostal"
                descripcion="estado"
                :clearable="false"
                :value-as-object="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col v-if="domicilio['@CodigoPostal'].value" cols="3">
          <b-form-group label="Estado" label-for="Estado">
            <validation-provider
              #default="{ errors }"
              name="Estado"
              :vid="'Estado_' + index"
              :rules="{
                required: true,
              }"
            >
              <AjaxVueSelect
                id="Estado"
                v-model="domicilio['@Estado']"
                label="title"
                catalogo="catEstado"
                clave="estado"
                descripcion="descripcion"
                :clearable="false"
                :search-on-focus="true"
                :search-terms="estado"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col v-if="municipio" cols="3">
          <b-form-group label="Municipio" label-for="Municipio">
            <validation-provider
              #default="{ errors }"
              name="Municipio"
              :vid="'Municipio_' + index"
              :rules="{
                required: true,
              }"
            >
              <AjaxVueSelect
                id="Municipio"
                v-model="domicilio['@Municipio']"
                label="title"
                catalogo="catMunicipio"
                clave="municipio"
                descripcion="descripcion"
                :clearable="false"
                :search-on-focus="true"
                :search-terms="municipio + '+' + estado"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col v-if="localidad" cols="3">
          <b-form-group label="Localidad" label-for="Localidad">
            <validation-provider
              #default="{ errors }"
              name="Localidad"
              :vid="'Localidad_' + index"
              :rules="{
                required: true,
              }"
            >
              <AjaxVueSelect
                id="Localidad"
                v-model="domicilio['@Localidad']"
                label="title"
                catalogo="catLocalidad"
                clave="localidad"
                descripcion="descripcion"
                :clearable="false"
                :search-on-focus="true"
                :search-terms="localidad + '+' + estado"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b-form-group label="Calle" label-for="Calle">
            <validation-provider
              #default="{ errors }"
              name="Calle"
              :vid="'Calle_' + index"
              :rules="{
                max: 100,
              }"
            >
              <b-form-input
                id="Calle"
                v-model="domicilio['@Calle']"
                placeholder=""
                maxlength="100"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Numero exterior" label-for="NumeroExterior">
            <b-form-input
              id="NumeroExterior"
              v-model="domicilio['@NumeroExterior']"
              placeholder=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Numero interior" label-for="NumeroInterior">
            <b-form-input
              id="NumeroInterior"
              v-model="domicilio['@NumeroInterior']"
              placeholder=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Colonia" label-for="Colonia">
            <AjaxVueSelect
              id="Colonia"
              v-model="domicilio['@Colonia']"
              label="title"
              catalogo="catColonia"
              clave="colonia"
              descripcion="descripcion"
              :clearable="false"
              :search-on-focus="true"
              :search-terms="
                domicilio['@CodigoPostal']
                  ? domicilio['@CodigoPostal'].value
                  : ''
              "
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Referencia" label-for="Referencia">
            <b-form-input
              id="Referencia"
              v-model="domicilio['@Referencia']"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template v-if="tipoRemitente === 'extranjero'">
      <b-row>
        <b-col cols="3">
          <b-form-group label="Calle" label-for="Calle">
            <validation-provider
              #default="{ errors }"
              name="Calle"
              :vid="'eCalle_' + index"
              :rules="{
                max: 100,
              }"
            >
              <b-form-input
                id="Calle"
                v-model="domicilio['@Calle']"
                placeholder=""
                maxlength="100"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Numero exterior" label-for="NumeroExterior">
            <b-form-input
              id="NumeroExterior"
              v-model="domicilio['@NumeroExterior']"
              placeholder=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Numero interior" label-for="NumeroInterior">
            <b-form-input
              id="NumeroInterior"
              v-model="domicilio['@NumeroInterior']"
              placeholder=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Colonia" label-for="Colonia">
            <b-form-input
              id="Colonia"
              v-model="domicilio['@Colonia']"
              placeholder=""
              maxlength="120"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Referencia" label-for="Referencia">
            <b-form-input
              id="Referencia"
              v-model="domicilio['@Referencia']"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group label="Pais" label-for="Pais">
            <validation-provider
              #default="{ errors }"
              name="Pais"
              :vid="'ePais_' + index"
              :rules="{
                required: true,
              }"
            >
              <AjaxVueSelect
                id="Pais"
                v-model="pais"
                label="title"
                catalogo="catPais"
                clave="pais"
                descripcion="descripcion"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Estado" label-for="Estado">
            <validation-provider
              #default="{ errors }"
              name="Estado"
              :vid="'eEstado_' + index"
              :rules="{
                required: true,
              }"
            >
              <AjaxVueSelect
                v-if="['USA', 'CAN', 'MEX'].includes(domicilio['@Pais'].value)"
                id="Estado"
                v-model="domicilio['@Estado']"
                label="title"
                catalogo="catEstado"
                clave="estado"
                descripcion="descripcion"
                :clearable="false"
                :search-on-focus="true"
                :search-terms="
                  domicilio['@Pais'] ? domicilio['@Pais'].value : ''
                "
              />
              <b-form-input
                v-else
                id="Estado"
                v-model="domicilio['@Estado']"
                placeholder=""
                maxlength="30"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Municipio" label-for="Municipio">
            <b-form-input
              id="Municipio"
              v-model="domicilio['@Municipio']"
              placeholder=""
              maxlength="120"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Localidad" label-for="Localidad">
            <b-form-input
              id="Localidad"
              v-model="domicilio['@Localidad']"
              placeholder=""
              maxlength="120"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Codigo Postal" label-for="CodigoPostal">
            <validation-provider
              #default="{ errors }"
              name="Codigo Postal"
              :vid="'eCodigoPostal_' + index"
              :rules="{
                required: true,
              }"
            >
              <b-form-input
                id="CodigoPostal"
                v-model="codigoPostal"
                placeholder=""
                maxlength="12"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol, BFormInput } from 'bootstrap-vue'
import AjaxVueSelect from '@/components/AjaxVueSelect'
import 'flatpickr/dist/l10n/es'
import { validationRules } from '@/mixins/validationRules'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    AjaxVueSelect,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
  },
  mixins: [validationRules],
  props: {
    domicilio: {
      type: Object,
      default: null,
    },
    tipoRemitente: {
      type: String,
      default: '',
    },
    mostrar: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      codigoPostal: '',
      estado: '',
      municipio: '',
      localidad: '',
      pais: '',
    }
  },
  watch: {
    codigoPostal() {
      if (typeof this.codigoPostal.value === 'object') {
        this.domicilio['@Pais'] = {
          title: 'MEX - México',
          value: 'MEX',
        }
        this.domicilio['@CodigoPostal'] = {
          title: `${this.codigoPostal.value['codigoPostal']} - ${this.codigoPostal.value['estado']}`,
          value: this.codigoPostal.value['codigoPostal'],
        }
        this.estado = this.codigoPostal.value['estado'] || ''
        this.municipio = this.codigoPostal.value['municipio'] || ''
        this.localidad = this.codigoPostal.value['localidad'] || ''

        this.domicilio['@Calle'] = ''
        this.domicilio['@NumeroExterior'] = ''
        this.domicilio['@NumeroInterior'] = ''
        this.domicilio['@Colonia'] = ''
        this.domicilio['@Localidad'] = ''
        this.domicilio['@Referencia'] = ''
        this.domicilio['@Municipio'] = ''
        this.domicilio['@Estado'] = ''
      } else {
        this.domicilio['@CodigoPostal'] = this.codigoPostal
      }
    },
    pais(newValue, oldValue) {
      this.domicilio['@Pais'] = this.pais
      if (
        typeof oldValue === 'object' &&
        typeof newValue === 'object' &&
        typeof this.domicilio['@Estado'] === 'object' &&
        !['USA', 'CAN', 'MEX'].includes(this.pais.value)
      ) {
        this.domicilio['@Estado'] = ''
      }
    },
    tipoRemitente(newValue) {
      if (newValue === 'nacional') {
        this.pais = {
          title: 'MEX - México',
          value: 'MEX',
        }
      } else {
        this.pais = ''
      }
      // Cada que cambie le tipo de remitente se borran valores
      this.domicilio['@Calle'] = ''
      this.domicilio['@NumeroExterior'] = ''
      this.domicilio['@NumeroInterior'] = ''
      this.domicilio['@Colonia'] = ''
      this.domicilio['@Localidad'] = ''
      this.domicilio['@Referencia'] = ''
      this.domicilio['@Municipio'] = ''
      this.domicilio['@Estado'] = ''
      this.domicilio['@CodigoPostal'] = ''

      this.codigoPostal = this.domicilio['@CodigoPostal'] || ''
      this.estado = this.domicilio['@Estado'] || ''
      this.municipio = this.domicilio['@Municipio'] || ''
      this.localidad = this.domicilio['@Localidad'] || ''
    },
  },
  created() {
    this.codigoPostal = this.domicilio['@CodigoPostal'] || ''
    this.estado = this.domicilio['@Estado'] || ''
    this.municipio = this.domicilio['@Municipio'] || ''
    this.localidad = this.domicilio['@Localidad'] || ''
    this.pais = this.domicilio['@Pais'] || ''
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
