var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[(_vm.mostrar['@RFCFigura'])?_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"RFC","label-for":"RFCFigura"}},[_c('validation-provider',{attrs:{"name":"RFC en figura transporte","vid":'RFCFigura_' + _vm.index,"rules":{
            required: true,
            regex: _vm.regexRfcMutable,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"RFCFigura","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"13"},model:{value:(_vm.datos['@RFCFigura']),callback:function ($$v) {_vm.$set(_vm.datos, '@RFCFigura', $$v)},expression:"datos['@RFCFigura']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,262710967)})],1)],1):_vm._e(),(_vm.mostrar['@NumRegIdTribFigura'])?_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Id tributario","label-for":"NumRegIdTribFigura"}},[_c('validation-provider',{attrs:{"name":"Id tributario","vid":'NumRegIdTribFigura_' + _vm.index,"rules":{
            required: true,
            regex: _vm.regexNumRegIdTrib,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"NumRegIdTribFigura","placeholder":""},model:{value:(_vm.datos['@NumRegIdTribFigura']),callback:function ($$v) {_vm.$set(_vm.datos, '@NumRegIdTribFigura', $$v)},expression:"datos['@NumRegIdTribFigura']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2521417214)})],1)],1):_vm._e(),(_vm.mostrar['@NumLicencia'])?_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Número de licencia","label-for":"NumLicencia"}},[_c('validation-provider',{attrs:{"name":"Número de licencia","vid":'NumLicencia_' + _vm.index,"rules":{
            regex: _vm.regexNumLicencia,
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"NumLicencia","state":errors.length > 0 ? false : null,"maxlength":"16"},model:{value:(_vm.datos['@NumLicencia']),callback:function ($$v) {_vm.$set(_vm.datos, '@NumLicencia', $$v)},expression:"datos['@NumLicencia']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2250333543)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"NombreFigura"}},[_c('validation-provider',{attrs:{"name":"Nombre","vid":'NombreFigura_' + _vm.index,"rules":{
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"NombreFigura","placeholder":"","maxlength":"254"},model:{value:(_vm.datos['@NombreFigura']),callback:function ($$v) {_vm.$set(_vm.datos, '@NombreFigura', $$v)},expression:"datos['@NombreFigura']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[(_vm.mostrar['@ResidenciaFiscalFigura'])?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Residencia fiscal","label-for":"ResidenciaFiscalFigura"}},[_c('AjaxVueSelect',{attrs:{"id":"ResidenciaFiscalFigura","label":"title","catalogo":"catPais","clave":"pais","descripcion":"descripcion","clearable":false},model:{value:(_vm.datos['@ResidenciaFiscalFigura']),callback:function ($$v) {_vm.$set(_vm.datos, '@ResidenciaFiscalFigura', $$v)},expression:"datos['@ResidenciaFiscalFigura']"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }