<template>
  <div>
    <b-row>
      <b-col cols="2">
        <b-form-group label="Tipo de ubicación" label-for="TipoUbicacion">
          <v-select
            id="TipoUbicacion"
            v-model="ubicacion['@TipoUbicacion']"
            label="title"
            :options="TipoUbicacion_options"
            :clearable="false"
            @close="TipoUbicacion_closed"
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Tipo de transporte" label-for="TipoTransporte">
          <v-select
            id="TipoTransporte"
            v-model="ubicacion.TipoTransporte"
            label="title"
            :options="TipoTransporte_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group
          :label="
            ubicacion['@TipoUbicacion'].value === 'Origen'
              ? 'Tipo de remitente'
              : 'Tipo de destinatario'
          "
          label-for="TipoRemitenteODestinatario"
        >
          <v-select
            id="TipoRemitenteODestinatario"
            v-model="ubicacion.TipoRemitenteODestinatario"
            label="title"
            :options="TipoRemitenteODestinatario_options"
            :clearable="false"
            @close="TipoRemitenteODestinatario_closed"
          />
        </b-form-group>
      </b-col>
      <b-col v-show="mostrar['@TipoEstacion']" cols="3">
        <b-form-group label="Tipo de estacion" label-for="TipoEstacion">
          <v-select
            id="TipoEstacion"
            v-model="ubicacion['@TipoEstacion']"
            label="title"
            :options="TipoEstacion_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="ubicacion['@TipoUbicacion'].value !== 'Origen'" cols="2">
        <b-form-group
          label="Kilómetros recorridos"
          label-for="DistanciaRecorrida"
        >
          <validation-provider
            #default="{ errors }"
            name="Distancia recorrida"
            :vid="'DistanciaRecorrida_' + index"
            :rules="{
              required: true,
              between: [0.01, 99999],
            }"
          >
            <cleave
              id="DistanciaRecorrida"
              v-model="ubicacion['@DistanciaRecorrida']"
              class="form-control"
              placeholder="0"
              :options="cleave_options.number_with_comma"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-form-group
          :label="
            ubicacion['@TipoUbicacion'].value === 'Origen'
              ? 'Fecha y hora de salida'
              : 'Fecha y hora de llegada'
          "
          label-for="FechaHoraSalidaLlegada"
        >
          <validation-provider
            #default="{ errors }"
            :name="
              ubicacion['@TipoUbicacion'].value === 'Origen'
                ? 'Fecha y hora de salida'
                : 'Fecha y hora de llegada'
            "
            :vid="'FechaHoraSalidaLlegada_' + index"
            :rules="{
              required: true,
            }"
          >
            <flat-pickr
              id="FechaHoraSalidaLlegada"
              v-model="ubicacion['@FechaHoraSalidaLlegada']"
              class="form-control"
              :config="flatPickrConfig"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="mostrarIDUbicacion()" cols="2">
        <b-form-group label="IDUbicacion" label-for="IDUbicacion">
          <validation-provider
            #default="{ errors }"
            name="IDUbicacion"
            :vid="'IDUbicacion_' + index"
            :rules="{
              digits: 6,
              required: true,
            }"
          >
            <cleave
              id="IDUbicacion"
              v-model="idubicacion"
              class="form-control"
              placeholder="012345"
              :options="cleave_options.prefix_origen"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="
          ['nacional'].includes(this.ubicacion.TipoRemitenteODestinatario.valx)
        "
        cols="2"
      >
        <b-form-group
          :label="
            ubicacion['@TipoUbicacion'].value === 'Origen'
              ? 'RFC remitente'
              : 'RFC destinatario'
          "
          label-for="RFCRemitenteDestinatario"
        >
          <validation-provider
            #default="{ errors }"
            :name="
              ubicacion['@TipoUbicacion'].value === 'Origen'
                ? 'RFC remitente'
                : 'RFC destinatario'
            "
            :vid="'RFCRemitenteDestinatario_' + index"
            :rules="{
              regex: regexRfc,
              required: true,
            }"
          >
            <b-form-input
              id="RFCRemitenteDestinatario"
              v-model="ubicacion['@RFCRemitenteDestinatario']"
              placeholder="XAXX010101000"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@NumRegIdTrib']" cols="2">
        <b-form-group label="Id tributario" label-for="NumRegIdTrib">
          <validation-provider
            #default="{ errors }"
            name="Id tributario"
            :vid="'NumRegIdTrib_' + index"
            :rules="{
              required: true,
              regex: regexNumRegIdTrib,
            }"
          >
            <b-form-input
              id="NumRegIdTrib"
              v-model="ubicacion['@NumRegIdTrib']"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          :label="
            ubicacion['@TipoUbicacion'].value === 'Origen'
              ? 'Nombre del remitente'
              : 'Nombre del destinatario'
          "
          label-for="NombreRemitenteDestinatario"
        >
          <b-form-input
            id="NombreRemitenteDestinatario"
            v-model="ubicacion['@NombreRemitenteDestinatario']"
            placeholder=""
            maxlength="254"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="mostrar['@ResidenciaFiscal']" cols="4">
        <b-form-group label="Residencia fiscal" label-for="ResidenciaFiscal">
          <validation-provider
            #default="{ errors }"
            name="Residencia fiscal"
            :vid="'ResidenciaFiscal_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="ResidenciaFiscal"
              v-model="ubicacion['@ResidenciaFiscal']"
              label="title"
              catalogo="catPais"
              clave="pais"
              descripcion="descripcion"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-show="mostrar['@NumEstacion']" cols="2">
        <b-form-group label="Número de estacion" label-for="NumEstacion">
          <b-form-input
            id="NumEstacion"
            v-model="ubicacion['@NumEstacion']"
            placeholder=""
          />
        </b-form-group>
      </b-col>
      <b-col v-show="mostrar['@NombreEstacion']" cols="4">
        <b-form-group label="Nombre de estacion" label-for="NombreEstacion">
          <b-form-input
            id="NombreEstacion"
            v-model="ubicacion['@NombreEstacion']"
            placeholder=""
          />
        </b-form-group>
      </b-col>
      <b-col v-show="mostrar['@NavegacionTrafico']" cols="2">
        <b-form-group label="Navegacion Trafico" label-for="NavegacionTrafico">
          <v-select
            id="NavegacionTrafico"
            v-model="ubicacion['@NavegacionTrafico']"
            label="title"
            :options="NavegacionTrafico_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <Domicilio
      :domicilio="ubicacion['Domicilio']"
      :tipo-remitente="ubicacion.TipoRemitenteODestinatario.valx"
      :index="index"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BFormGroup, BRow, BCol, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { validationRules } from '@/mixins/validationRules'
import { clearValuesOnHide } from '@/mixins/clearValuesOnHide'
import { cleaveOptions } from '@/mixins/cleaveOptions'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import Domicilio from '@/views/CartaPorte/Domicilio'
import AjaxVueSelect from '@/components/AjaxVueSelect'
import { flatPickrConfig } from '@/mixins/flatPickrConfig'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    Domicilio,
    BFormGroup,
    Cleave,
    BRow,
    BCol,
    vSelect,
    AjaxVueSelect,
    BFormInput,
    flatPickr,
    ValidationProvider,
  },
  mixins: [cleaveOptions, flatPickrConfig, validationRules, clearValuesOnHide],
  props: {
    ubicacion: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      idubicacion: '',
      TipoEstacion_options: [
        { title: 'Origen Nacional', value: '01' },
        { title: 'Intermedia', value: '02' },
        { title: 'Destino Final Nacional', value: '03' },
      ],
      TipoTransporte_options: [
        { title: 'Autotransporte', valx: '01' },
        { title: 'Transporte Marítimo', valx: '02' },
        { title: 'Transporte Aéreo', valx: '03' },
        { title: 'Transporte Ferroviario', valx: '04' },
      ],
      TipoRemitenteODestinatario_options: [
        { title: 'Nacional', valx: 'nacional' },
        { title: 'Extranjero', valx: 'extranjero' },
      ],
      TipoUbicacion_options: [
        { title: 'Origen', value: 'Origen' },
        { title: 'Destino', value: 'Destino' },
      ],
      NavegacionTrafico_options: [
        { title: 'Altura', value: 'Altura' },
        { title: 'Cabotaje', value: 'Cabotaje' },
      ],
    }
  },
  computed: {
    mostrar() {
      let campos = {
        '@TipoEstacion': ['02', '03', '04'].includes(
          this.ubicacion.TipoTransporte.valx
        ),
        '@NavegacionTrafico': ['02'].includes(
          this.ubicacion.TipoTransporte.valx
        ),
        '@NombreEstacion': ['02', '03', '04'].includes(
          this.ubicacion.TipoTransporte.valx
        ),
        '@NumEstacion': ['02', '03', '04'].includes(
          this.ubicacion.TipoTransporte.valx
        ),
        '@ResidenciaFiscal': ['extranjero'].includes(
          this.ubicacion.TipoRemitenteODestinatario.valx
        ),
        '@NumRegIdTrib': ['extranjero'].includes(
          this.ubicacion.TipoRemitenteODestinatario.valx
        ),
        '@IDUbicacion': this.getTiposDeUbicaciones.length > 2,
      }

      // Borra todos los valores del grupo no seleccionado
      /*
      if (this.ubicacion['@TipoUbicacion'].value === 'Origen') {
        Object.keys(this.ubicacion.Destino).map((key, value) => {
          this.ubicacion.Destino[key] = ''
        })
      } else if (this.ubicacion['@TipoUbicacion'].value === 'destino') {
        Object.keys(this.ubicacion.Origen).map((key, value) => {
          this.ubicacion.Origen[key] = ''
        })
      }
       */

      this.clearValuesOnHide(this.ubicacion, campos)
      return campos
    },
    ...mapGetters('CartaPorte', ['getTiposDeUbicaciones']),
  },
  watch: {
    idubicacion(newValue) {
      if (newValue !== '') {
        if (this.ubicacion['@TipoUbicacion'].value === 'Origen') {
          this.ubicacion['@IDUbicacion'] = 'OR' + newValue
        } else {
          this.ubicacion['@IDUbicacion'] = 'DE' + newValue
        }
      } else {
        this.ubicacion['@IDUbicacion'] = ''
      }
    },
  },
  created() {
    this.idubicacion = this.ubicacion['@IDUbicacion'].substring(2)
  },
  methods: {
    mostrarIDUbicacion() {
      if (!this.mostrar['@IDUbicacion']) this.idubicacion = ''
      return this.mostrar['@IDUbicacion']
    },
    TipoUbicacion_closed() {
      if (this.ubicacion['@TipoUbicacion'].value === 'Origen') {
        this.ubicacion['@DistanciaRecorrida'] = ''
      }
      if (this.ubicacion['@IDUbicacion'] !== '') {
        if (this.ubicacion['@TipoUbicacion'].value === 'Origen') {
          this.ubicacion['@IDUbicacion'] = 'OR' + this.idubicacion
        } else {
          this.ubicacion['@IDUbicacion'] = 'DE' + this.idubicacion
        }
      }
    },
    TipoRemitenteODestinatario_closed() {
      if (this.ubicacion.TipoRemitenteODestinatario.valx === 'nacional') {
        this.ubicacion['@RFCRemitenteDestinatario'] = ''
      } else {
        this.ubicacion['@RFCRemitenteDestinatario'] = 'XEXX010101000'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
