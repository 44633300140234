const Espanol = require('flatpickr/dist/l10n/es.js').default.es

export const flatPickrAAAAMMDDConfig = {
  data() {
    return {
      flatPickrAAAAMMDDConfig: {
        enableTime: false,
        enableSeconds: false,
        time_24hr: false,
        dateFormat: 'Y-m-d',
        locale: Espanol,
      },
    }
  },
}
