var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Clave de permiso SCT","label-for":"PermSCT"}},[_c('validation-provider',{attrs:{"name":"Clave de permiso SCT","vid":'PermSCT_' + _vm.index,"rules":{
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"PermSCT","label":"title","catalogo":"catTipoPermiso","clave":"clave","descripcion":"descripcion","value-as-object":false,"clearable":false},model:{value:(_vm.transporte['@PermSCT']),callback:function ($$v) {_vm.$set(_vm.transporte, '@PermSCT', $$v)},expression:"transporte['@PermSCT']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Número de permiso SCT","label-for":"NumPermisoSCT"}},[_c('validation-provider',{attrs:{"name":"Número de permiso SCT","vid":'NumPermisoSCT_' + _vm.index,"rules":{
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"NumPermisoSCT","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"50"},model:{value:(_vm.transporte['@NumPermisoSCT']),callback:function ($$v) {_vm.$set(_vm.transporte, '@NumPermisoSCT', $$v)},expression:"transporte['@NumPermisoSCT']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Configuración vehicular","label-for":"ConfigVehicular"}},[_c('validation-provider',{attrs:{"name":"Configuración vehicular","vid":'ConfigVehicular_' + _vm.index,"rules":{
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"ConfigVehicular","label":"title","catalogo":"catConfigAutotransporte","clave":"clave","descripcion":"descripcion","value-as-object":false,"clearable":false},model:{value:(_vm.transporte.IdentificacionVehicular['@ConfigVehicular']),callback:function ($$v) {_vm.$set(_vm.transporte.IdentificacionVehicular, '@ConfigVehicular', $$v)},expression:"transporte.IdentificacionVehicular['@ConfigVehicular']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Peso bruto vehicular (toneladas)","label-for":"PesoBrutoVehicular"}},[_c('validation-provider',{attrs:{"name":"Peso bruto vehicular","vid":'PesoBrutoVehicular_' + _vm.index,"rules":{
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"PesoBrutoVehicular","placeholder":"0","options":_vm.cleave_options.number_with_comma_2d},model:{value:(
              _vm.transporte.IdentificacionVehicular['@PesoBrutoVehicular']
            ),callback:function ($$v) {_vm.$set(_vm.transporte.IdentificacionVehicular, '@PesoBrutoVehicular', $$v)},expression:"\n              transporte.IdentificacionVehicular['@PesoBrutoVehicular']\n            "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Placa vehicular","label-for":"PlacaVM"}},[_c('validation-provider',{attrs:{"name":"Placa vehicular","vid":'PlacaVM_' + _vm.index,"rules":{
            regex: _vm.regexPlacaVM,
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"PlacaVM","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"7"},model:{value:(_vm.transporte.IdentificacionVehicular['@PlacaVM']),callback:function ($$v) {_vm.$set(_vm.transporte.IdentificacionVehicular, '@PlacaVM', $$v)},expression:"transporte.IdentificacionVehicular['@PlacaVM']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Año del vehículo","label-for":"AnioModeloVM"}},[_c('validation-provider',{attrs:{"name":"Año del vehículo","vid":'AnioModeloVM_' + _vm.index,"rules":{
            regex: _vm.regexAnioModeloVM,
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"PlacaVM","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"4"},model:{value:(_vm.transporte.IdentificacionVehicular['@AnioModeloVM']),callback:function ($$v) {_vm.$set(_vm.transporte.IdentificacionVehicular, '@AnioModeloVM', $$v)},expression:"transporte.IdentificacionVehicular['@AnioModeloVM']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"Nombre de aseguradora para responsabilidad civil","label-for":"AseguraRespCivil"}},[_c('validation-provider',{attrs:{"name":"Nombre de aseguradora para responsabilidad civil","vid":'AseguraRespCivil_' + _vm.index,"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"AseguraRespCivil","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"50"},model:{value:(_vm.transporte.Seguros['@AseguraRespCivil']),callback:function ($$v) {_vm.$set(_vm.transporte.Seguros, '@AseguraRespCivil', $$v)},expression:"transporte.Seguros['@AseguraRespCivil']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Número de póliza","label-for":"PolizaRespCivil"}},[_c('validation-provider',{attrs:{"name":"Número de póliza","vid":'PolizaRespCivil_' + _vm.index,"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"PolizaRespCivil","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"30"},model:{value:(_vm.transporte.Seguros['@PolizaRespCivil']),callback:function ($$v) {_vm.$set(_vm.transporte.Seguros, '@PolizaRespCivil', $$v)},expression:"transporte.Seguros['@PolizaRespCivil']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.mostrarAseguradoraMedioAmbiente())?_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"Nombre de aseguradora para medio ambiente","label-for":"AseguraMedAmbiente"}},[_c('validation-provider',{attrs:{"name":"Nombre de aseguradora para medio ambiente","vid":'AseguraMedAmbiente_' + _vm.index,"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"AseguraMedAmbiente","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"50"},model:{value:(_vm.transporte.Seguros['@AseguraMedAmbiente']),callback:function ($$v) {_vm.$set(_vm.transporte.Seguros, '@AseguraMedAmbiente', $$v)},expression:"transporte.Seguros['@AseguraMedAmbiente']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2347108767)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Número de póliza","label-for":"PolizaMedAmbiente"}},[_c('validation-provider',{attrs:{"name":"Número de póliza","vid":'PolizaMedAmbiente_' + _vm.index,"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"PolizaMedAmbiente","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"30"},model:{value:(_vm.transporte.Seguros['@PolizaMedAmbiente']),callback:function ($$v) {_vm.$set(_vm.transporte.Seguros, '@PolizaMedAmbiente', $$v)},expression:"transporte.Seguros['@PolizaMedAmbiente']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3014304441)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"Nombre de aseguradora para la carga","label-for":"AseguraCarga"}},[_c('validation-provider',{attrs:{"name":"Nombre de aseguradora para la carga","vid":'AseguraCarga_' + _vm.index,"rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"AseguraCarga","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"50"},model:{value:(_vm.transporte.Seguros['@AseguraCarga']),callback:function ($$v) {_vm.$set(_vm.transporte.Seguros, '@AseguraCarga', $$v)},expression:"transporte.Seguros['@AseguraCarga']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Número de póliza","label-for":"PolizaCarga"}},[_c('validation-provider',{attrs:{"name":"Número de póliza","vid":'PolizaCarga_' + _vm.index,"rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"PolizaCarga","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"30"},model:{value:(_vm.transporte.Seguros['@PolizaCarga']),callback:function ($$v) {_vm.$set(_vm.transporte.Seguros, '@PolizaCarga', $$v)},expression:"transporte.Seguros['@PolizaCarga']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Prima de seguro (convenido entre transportista y cliente)","label-for":"PrimaSeguro"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"PrimaSeguro","placeholder":"","options":_vm.cleave_options.number_with_comma},model:{value:(_vm.transporte.Seguros['@PrimaSeguro']),callback:function ($$v) {_vm.$set(_vm.transporte.Seguros, '@PrimaSeguro', $$v)},expression:"transporte.Seguros['@PrimaSeguro']"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.mostrarRemolques())?_c('Remolques',{attrs:{"remolques":_vm.transporte.Remolques.Remolque,"min":_vm.min_remolques}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }