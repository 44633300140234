<template>
  <v-select
    v-bind="$attrs"
    label="title"
    :filterable="false"
    :value="value"
    :options="options"
    :clearable="clearable"
    @search="fetchOptions"
    @search:focus="fetchOptionsFocus"
    @input="$emit('input', $event)"
  >
    <template slot="no-options">{{ searchInfo }}</template>
    <template slot="option" slot-scope="option">
      <div>{{ option.title }}</div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div>{{ option.title }}</div>
    </template>
  </v-select>
</template>

<script>
import _debounce from 'lodash/debounce'
import CartaPorte from '@/services/CartaPorte'
import vSelect from 'vue-select'

export default {
  name: 'AjaxVueSelect',
  components: {
    vSelect,
  },
  props: [
    'value',
    'catalogo',
    'clave',
    'descripcion',
    'valueAsObject',
    'clearable',
    'searchTerms',
    'searchOnFocus',
  ],
  data() {
    return {
      options: [],
      search: '',
    }
  },
  computed: {
    searchInfo() {
      if (this.search.length < 1) {
        return 'Ingresa al menos 2 dígitos ...'
      } else {
        return 'No se encontraron resultados.'
      }
    },
  },
  methods: {
    fetchOptionsFocus() {
      if (this.searchOnFocus) {
        this.fetchOptions('', () => {})
      }
    },
    fetchOptions: _debounce(function (search, loading) {
      if (this.searchTerms) {
        search += `+${this.searchTerms}`
      }
      // Para searchInfo
      this.search = search
      if (search && search.length > 1) {
        loading(true)
        CartaPorte[this.catalogo](search)
          .then((response) => {
            this.options = response.data.results.map((elemento) => ({
              id: elemento['id'],
              title: elemento[this.descripcion]
                ? elemento[this.clave] + ' - ' + elemento[this.descripcion]
                : elemento[this.clave],
              value: this.valueAsObject ? elemento : elemento[this.clave],
            }))
          })
          .catch((error) => {
            // console.log('Error: ' + error.response)
          })
        loading(false)
      }
    }, 1000),
  },
}
</script>

<style scoped></style>
