<template>
  <div>
    <b-row>
      <b-col cols="3">
        <b-form-group
          label="Transporte internacional"
          label-for="TranspInternac"
        >
          <v-select
            id="TranspInternac"
            v-model="TranspInternac"
            label="title"
            :options="TranspInternac_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <template v-if="mostrar['EntradaSalidaMerc']">
        <b-col cols="3">
          <b-form-group
            label="Entrada o salida del territorio"
            label-for="EntradaSalidaMerc"
          >
            <validation-provider
              #default="{ errors }"
              name="Entrada o salida del territorio"
              :vid="'EntradaSalidaMerc'"
              :rules="{
                required: true,
              }"
            >
              <v-select
                id="EntradaSalidaMerc"
                v-model="EntradaSalidaMerc"
                label="title"
                :options="EntradaSalidaMerc_options"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            :label="
              EntradaSalidaMerc.value === 'Entrada'
                ? 'Pais origen'
                : 'Pais destino'
            "
            label-for="PaisOrigenDestino"
          >
            <validation-provider
              #default="{ errors }"
              name="Pais"
              :vid="'PaisOrigenDestino'"
              :rules="{
                required: true,
              }"
            >
              <AjaxVueSelect
                id="PaisOrigenDestino"
                v-model="PaisOrigenDestino"
                label="title"
                catalogo="catPais"
                clave="pais"
                descripcion="descripcion"
                :clearable="false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <RegimenesAduaneros />
        </b-col>
      </template>
    </b-row>
    <b-row>
      <b-col cols="3" class="mt-1">
        <b-form-group label="Registro ISTMO" label-for="RegistroISTMO">
          <v-select
            id="RegistroISTMO"
            v-model="RegistroISTMO"
            label="title"
            :options="RegistroISTMO_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <template v-if="mostrar['RegistroISTMO']">
        <b-col cols="3">
          <b-form-group
            label="Ubicacion polo origen"
            label-for="UbicacionPoloOrigen"
          >
            <AjaxVueSelect
              id="UbicacionPoloOrigen"
              v-model="UbicacionPoloOrigen"
              label="title"
              catalogo="catRegistroISTMO"
              clave="clave"
              descripcion="descripcion"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Ubicacion polo destino"
            label-for="UbicacionPoloDestino"
          >
            <AjaxVueSelect
              id="UbicacionPoloDestino"
              v-model="UbicacionPoloDestino"
              label="title"
              catalogo="catRegistroISTMO"
              clave="clave"
              descripcion="descripcion"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol } from 'bootstrap-vue'
import AjaxVueSelect from '@/components/AjaxVueSelect'
import vSelect from 'vue-select'
import { mapFields } from 'vuex-map-fields'
import { clearValuesOnHide } from '@/mixins/clearValuesOnHide'
import RegimenesAduaneros from '@/views/CartaPorte/RegimenesAduaneros.vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    RegimenesAduaneros,
    AjaxVueSelect,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
  },
  mixins: [clearValuesOnHide],
  data() {
    return {
      TranspInternac_options: [
        { title: 'Sí', value: 'Sí' },
        { title: 'No', value: 'No' },
      ],
      EntradaSalidaMerc_options: [
        { title: 'Entrada', value: 'Entrada' },
        { title: 'Salida', value: 'Salida' },
      ],
      RegistroISTMO_options: [
        { title: 'Sí', value: 'Sí' },
        { title: 'No', value: '' },
      ],
    }
  },
  computed: {
    ...mapFields('CartaPorte', {
      TranspInternac: 'objJson.complemento.CartaPorte.@TranspInternac',
      EntradaSalidaMerc: 'objJson.complemento.CartaPorte.@EntradaSalidaMerc',
      RegimenesAduaneros:
        'objJson.complemento.CartaPorte.RegimenesAduaneros.RegimenAduaneroCCP',
      PaisOrigenDestino: 'objJson.complemento.CartaPorte.@PaisOrigenDestino',
      ViaEntradaSalida: 'objJson.complemento.CartaPorte.@ViaEntradaSalida',
      RegistroISTMO: 'objJson.complemento.CartaPorte.@RegistroISTMO',
      UbicacionPoloOrigen:
        'objJson.complemento.CartaPorte.@UbicacionPoloOrigen',
      UbicacionPoloDestino:
        'objJson.complemento.CartaPorte.@UbicacionPoloDestino',
    }),
    mostrar() {
      let campos = {
        EntradaSalidaMerc: ['Sí'].includes(this.TranspInternac.value),
        PaisOrigenDestino: ['Sí'].includes(this.TranspInternac.value),
        ViaEntradaSalida: ['Sí'].includes(this.TranspInternac.value),
        RegistroISTMO: ['Sí'].includes(this.RegistroISTMO.value),
        UbicacionPoloOrigen: ['Sí'].includes(this.RegistroISTMO.value),
        UbicacionPoloDestino: ['Sí'].includes(this.RegistroISTMO.value),
      }
      this.clearValuesOnHide(this, campos)
      return campos
    },
  },
  watch: {
    TranspInternac(newValue, oldValue) {
      if (newValue.value === 'Sí') {
        this.ViaEntradaSalida = { title: 'Autotransporte', value: '01' }
      } else {
        // Aqui es el proceso para borrar los regimenes aduaneros cuando se cambia la opción de Transporte internacional
        this.RegimenesAduaneros = []
      }
    },
    // EntradaSalidaMerc(newValue, oldValue) {
    //   this.RegimenAduanero = ''
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
