var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.remolque)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('b-form-group',{attrs:{"label":"Subtipo de remolque","label-for":"SubTipoRem"}},[_c('validation-provider',{attrs:{"name":"Subtipo de remolque","vid":'SubTipoRem_' + _vm.index,"rules":{
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"SubTipoRem","label":"title","catalogo":"catSubTipoRem","clave":"clave","descripcion":"descripcion","value-as-object":false,"clearable":false},model:{value:(_vm.remolque['@SubTipoRem']),callback:function ($$v) {_vm.$set(_vm.remolque, '@SubTipoRem', $$v)},expression:"remolque['@SubTipoRem']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3063135279)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Placa","label-for":"Placa"}},[_c('validation-provider',{attrs:{"name":"Placa","vid":'Placa_' + _vm.index,"rules":{
            regex: _vm.regexPlacaVM,
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Placa","placeholder":"","state":errors.length > 0 ? false : null,"maxlength":"7"},model:{value:(_vm.remolque['@Placa']),callback:function ($$v) {_vm.$set(_vm.remolque, '@Placa', $$v)},expression:"remolque['@Placa']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2668241207)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }