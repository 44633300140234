var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cantidad)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Cantidad","label-for":"cantidad"}},[_c('validation-provider',{attrs:{"name":"Cantidad","vid":'cantidad_' + _vm.index,"rules":{
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cantidad","placeholder":"0","options":_vm.cleave_options.number_with_comma},model:{value:(_vm.cantidad['@Cantidad']),callback:function ($$v) {_vm.$set(_vm.cantidad, '@Cantidad', $$v)},expression:"cantidad['@Cantidad']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2571195814)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"ID origen","label-for":"IDOrigen"}},[_c('validation-provider',{attrs:{"name":"ID origen","vid":'IDOrigen_' + _vm.index,"rules":{
            digits: 6,
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"IDOrigen","placeholder":"012345","options":_vm.cleave_options.prefix_origen},model:{value:(_vm.idorigen),callback:function ($$v) {_vm.idorigen=$$v},expression:"idorigen"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4017302246)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"ID destino","label-for":"IDDestino"}},[_c('validation-provider',{attrs:{"name":"ID destino","vid":'IDDestino_' + _vm.index,"rules":{
            digits: 6,
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"IDDestino","placeholder":"012345","options":_vm.cleave_options.prefix_origen},model:{value:(_vm.iddestino),callback:function ($$v) {_vm.iddestino=$$v},expression:"iddestino"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4118705318)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }