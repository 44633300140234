const Espanol = require('flatpickr/dist/l10n/es.js').default.es

export const flatPickrConfig = {
  data() {
    return {
      flatPickrConfig: {
        enableTime: true,
        enableSeconds: true,
        time_24hr: true,
        dateFormat: 'Y-m-dTH:i:S',
        locale: Espanol,
      },
    }
  },
}
