<template>
  <div v-if="documentacionAduanera">
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Tipo de documento aduanero"
          label-for="TipoDocumento"
        >
          <validation-provider
            #default="{ errors }"
            name="Tipo de documento aduanero"
            :vid="'TipoDocumento_' + index"
            :rules="{
              required: EntradaSalidaMerc.value === 'Entrada',
            }"
          >
            <AjaxVueSelect
              id="TipoDocumento"
              v-model="documentacionAduanera['@TipoDocumento']"
              label="title"
              catalogo="catDocumentoAduanero"
              clave="clave"
              descripcion="descripcion"
              :search-terms="
                EntradaSalidaMerc.value == 'Entrada' ? 'Entrada' : 'Salida'
              "
              :clearable="true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="mostrar['@NumPedimento']" cols="4">
        <b-form-group label="Pedimento" label-for="NumPedimento">
          <validation-provider
            #default="{ errors }"
            name="Pedimento"
            :vid="'Pedimento_' + index"
            :rules="{
              required: true,
              regex: regexPedimento,
            }"
          >
            <cleave
              id="NumPedimento"
              v-model="documentacionAduanera['@NumPedimento']"
              class="form-control"
              placeholder="00  00  0000  0000000"
              :options="cleave_options.pedimento"
              :raw="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@IdentDocAduanero']" cols="4">
        <b-form-group
          label="Identificador del documento aduanero"
          label-for="IdentDocAduanero"
        >
          <validation-provider
            #default="{ errors }"
            name="documento aduanero"
            :vid="'IdentDocAduanero_' + index"
            :rules="{
              required: EntradaSalidaMerc.value === 'Entrada',
            }"
          >
            <b-form-input
              id="IdentDocAduanero"
              v-model="documentacionAduanera['@IdentDocAduanero']"
              placeholder=""
              maxlength="150"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@RFCImpo']" cols="4">
        <b-form-group label="RFC importador" label-for="RFCImpo">
          <validation-provider
            #default="{ errors }"
            name="RFC importador"
            :vid="'RFCImpo_' + index"
            :rules="{
              required: true,
              regex: regexRfc,
            }"
          >
            <b-form-input
              id="RFCImpo"
              v-model="documentacionAduanera['@RFCImpo']"
              placeholder="XAXX010101000"
              :state="errors.length > 0 ? false : null"
              maxlength="13"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol, BFormInput } from 'bootstrap-vue'
import { clearValuesOnHide } from '@/mixins/clearValuesOnHide'
import { validationRules } from '@/mixins/validationRules'
import Cleave from 'vue-cleave-component'
import { cleaveOptions } from '@/mixins/cleaveOptions'
import { ValidationProvider } from 'vee-validate'
import AjaxVueSelect from '@/components/AjaxVueSelect.vue'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    BFormInput,
    AjaxVueSelect,
    BFormGroup,
    BRow,
    BCol,
    Cleave,
    ValidationProvider,
  },
  mixins: [clearValuesOnHide, validationRules, cleaveOptions],
  props: {
    documentacionAduanera: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapFields('CartaPorte', {
      TranspInternac: 'objJson.complemento.CartaPorte.@TranspInternac',
      EntradaSalidaMerc: 'objJson.complemento.CartaPorte.@EntradaSalidaMerc',
    }),
    mostrar() {
      let campos = {
        '@NumPedimento':
          ['01'].includes(this.documentacionAduanera['@TipoDocumento'].value) &&
          ['Entrada'].includes(this.EntradaSalidaMerc.value),
        '@IdentDocAduanero': !['01'].includes(
          this.documentacionAduanera['@TipoDocumento'].value
        ),
        '@RFCImpo': ['01'].includes(
          this.documentacionAduanera['@TipoDocumento'].value
        ),
      }
      this.clearValuesOnHide(this.documentacionAduanera, campos)
      return campos
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
