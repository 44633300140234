<template>
  <div v-if="parte">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Parte del transporte" label-for="ParteTransporte">
          <v-select
            id="ParteTransporte"
            v-model="parte['@ParteTransporte']"
            label="title"
            :options="ParteTransporte_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    parte: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ParteTransporte_options: [
        { title: 'PT01 - Camión unitario', value: 'PT01' },
        { title: 'PT02 - Camión', value: 'PT02' },
        { title: 'PT03 - Tractocamión', value: 'PT03' },
        { title: 'PT04 - Remolque', value: 'PT04' },
        { title: 'PT05 - Semirremolque', value: 'PT05' },
        { title: 'PT06 - Vehículo ligero de carga', value: 'PT06' },
        { title: 'PT07 - Grúa', value: 'PT07' },
        { title: 'PT08 - Aeronave', value: 'PT08' },
        { title: 'PT09 - Barco o buque', value: 'PT09' },
        { title: 'PT10 - Carro o vagón', value: 'PT10' },
        { title: 'PT11 - Contenedor', value: 'PT11' },
        { title: 'PT12 - Locomotora', value: 'PT12' },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
