<template>
  <div>
    <div ref="form">
      <!-- Row Loop -->
      <b-row
        v-for="(ubicacion, index) in Ubicaciones"
        :id="index"
        :key="index"
        ref="row"
      >
        <!-- Item Name -->
        <b-col cols="10">
          <Ubicacion :ubicacion="ubicacion" :index="index" />
        </b-col>

        <!-- Remove Button -->
        <b-col cols="2">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon icon="XIcon" class="mr-25" />
            <span>Borrar</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="addItem"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Agregar ubicación</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import Ubicacion from '@/views/CartaPorte/Ubicacion'

export default {
  components: {
    Ubicacion,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      nextRowId: 0,
    }
  },
  computed: {
    ...mapMultiRowFields('CartaPorte', {
      Ubicaciones: 'objJson.complemento.CartaPorte.Ubicaciones.Ubicacion',
    }),
  },
  mounted() {
    this.initTrHeight()
    this.nextRowId = this.Ubicaciones.length
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions('CartaPorte', ['addUbicacion', 'deleteUbicacion']),
    addItem() {
      this.addUbicacion({
        '@TipoUbicacion': { title: 'Origen', value: 'Origen' },
        '@IDUbicacion': '',
        TipoTransporte: { title: 'Autotransporte', valx: '01' },
        TipoRemitenteODestinatario: { title: 'Nacional', valx: 'nacional' },
        '@RFCRemitenteDestinatario': '',
        '@NombreRemitenteDestinatario': '',
        '@NumRegIdTrib': '',
        '@ResidenciaFiscal': '',
        '@NumEstacion': '',
        '@NombreEstacion': '',
        '@NavegacionTrafico': '',
        '@FechaHoraSalidaLlegada': '',
        '@TipoEstacion': { title: '', value: '' },
        '@DistanciaRecorrida': '',
        Domicilio: {
          '@Calle': '',
          '@NumeroExterior': '',
          '@NumeroInterior': '',
          '@Colonia': '',
          '@Localidad': '',
          '@Referencia': '',
          '@Municipio': '',
          '@Estado': '',
          '@Pais': '',
          '@CodigoPostal': '',
        },
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.deleteUbicacion(index)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
