<template>
  <div>
    <div>
      <validation-observer ref="simpleRules" v-slot="{ errors }">
        <b-modal
          ref="modal-error"
          ok-only
          ok-variant="danger"
          ok-title="Aceptar"
          modal-class="modal-danger"
          centered
          title="Error al intentar guardar"
        >
          <b-card-text>
            <template v-if="modalError === 'ubicaciones'"
              >En el apartado de ubicaciones debe existir al menos un origen y
              un destino.</template
            >
            <template v-if="modalError === 'mercancias'"
              >Debe existir al menos una mercancia.</template
            >
            <template v-if="modalError === 'pedimentos'"
              >Es obligatorio agregar la información del pedimento cuando se
              trata de transporte internacional y entrada al territorio
              nacional.</template
            >
            <template v-if="modalError === 'errores'">
              <div v-for="(error, index) in errors" :key="index">
                <span v-if="error.length > 0">- {{ error[0] }}.</span>
              </div> </template
            ><template v-if="modalError === 'submit'"
              >Se detecto error al intentar guardar el complemento; por favor
              contacte a soporte vía chat o al correo soporte@ecofactura.mx para
              recibir apoyo.</template
            >
          </b-card-text>
        </b-modal>
        <b-form>
          <section id="card-navigation">
            <b-row>
              <!-- pill tabs inside card -->
              <b-col md="12">
                <b-card>
                  <b-tabs pills nav-class="mb-3">
                    <b-tab title="Datos generales" active>
                      <b-card-title>Datos generales</b-card-title>
                      <Generales />
                    </b-tab>
                    <b-tab title="Ubicaciones">
                      <b-card-title>Ubicaciones</b-card-title>
                      <Ubicaciones />
                    </b-tab>
                    <b-tab title="Mercancias">
                      <b-card-title>Mercancias</b-card-title>
                      <Mercancias />
                    </b-tab>
                    <b-tab title="Datos del transporte">
                      <b-card-title>Autotransporte</b-card-title>
                      <Autotransportes />
                    </b-tab>
                    <b-tab title="Figura del transporte">
                      <b-card-title>Figura del Transporte</b-card-title>
                      <FigurasTransportes />
                    </b-tab>
                    <b-tab title="Finalizar" class="text-center">
                      <b-card-title>Guardar complemento</b-card-title>
                      <b-card-text>
                        Para terminar y guardar el complemento de click en
                        guardar carta porte.
                      </b-card-text>
                      <b-card class="text-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="success"
                          type="submit"
                          @click.prevent="validationForm"
                        >
                          <feather-icon icon="SaveIcon" class="mr-25" />
                          <span>Guardar carta porte</span>
                        </b-button>
                      </b-card>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>
          </section>
        </b-form>
      </validation-observer>
    </div>
    <template v-if="desarrollo">
      <b-row>
        <b-col cols="12"
          ><b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="fetchComprobante(idComprobante)"
            >Obtener facturas
          </b-button>
        </b-col>
      </b-row>
      <pre>{{ objJson }}</pre>
    </template>
  </div>
</template>

<script>
import {
  BForm,
  BCard,
  BButton,
  BRow,
  BCol,
  BTab,
  BTabs,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { mapState, mapGetters, mapActions } from 'vuex'
import Generales from '@/views/CartaPorte/Generales'
import Ubicaciones from '@/views/CartaPorte/Ubicaciones'
import Mercancias from '@/views/CartaPorte/Mercancias'
import Autotransportes from '@/views/CartaPorte/Autotransportes'
import { ValidationObserver } from 'vee-validate'
import FigurasTransportes from '@/views/CartaPorte/FigurasTransportes'

export default {
  components: {
    FigurasTransportes,
    Autotransportes,
    Ubicaciones,
    Generales,
    Mercancias,
    BForm,
    BCard,
    BButton,
    BCol,
    BRow,
    BTab,
    BTabs,
    BCardTitle,
    BCardText,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      modalError: '',
    }
  },
  computed: {
    ...mapState(['idComprobante', 'desarrollo']),
    ...mapState('CartaPorte', ['objJson']),
    ...mapGetters('CartaPorte', [
      'getAsJson',
      'getTiposDeUbicaciones',
      'countMercancias',
    ]),
  },
  created() {
    this.fetchComprobante(this.idComprobante)
  },
  methods: {
    ...mapActions('CartaPorte', ['fetchComprobante', 'saveCartaPorte']),
    validacionesExtras() {
      if (
        !this.getTiposDeUbicaciones.includes('Origen') ||
        !this.getTiposDeUbicaciones.includes('Destino')
      ) {
        this.modalError = 'ubicaciones'
        this.$refs['modal-error'].show()
        return false
      }
      if (this.countMercancias < 1) {
        this.modalError = 'mercancias'
        this.$refs['modal-error'].show()
        return false
      }
      /*
      SE VERIFICA PEDIMENTOS DE OTRA FORMA
      if (
        this.objJson.complemento.CartaPorte['@TranspInternac'].value === 'Sí' &&
        this.objJson.complemento.CartaPorte['@EntradaSalidaMerc'].value ===
          'Entrada'
      ) {
        this.modalError = 'pedimentos'
        this.$refs['modal-error'].show()
        return false
      }
      */
      return true
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.validacionesExtras()) {
            this.saveCartaPorte()
              .then(() => {
                window.localStorage.clear()
                window.close()
              })
              .catch(() => {
                this.modalError = 'submit'
                this.$refs['modal-error'].show()
              })
          }
        } else {
          this.modalError = 'errores'
          this.$refs['modal-error'].show()
        }
      })
    },
  },
}
</script>
<style lang="scss">
hr {
  border: none;
  height: 25px;
  width: 100%;
  box-shadow: 0 20px 20px -11px #7a7a7a;
  margin: -5px auto 30px;
}
</style>
