export const validationRules = {
  data() {
    return {
      regexRfc:
        /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/,
      regexRfcPf:
        /^[A-Z&Ñ]{4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/,
      regexDimensiones: /^([0-9]{1,3}[/]){2}([0-9]{1,3})(cm|plg)$/,
      regexUUID:
        /^[a-f0-9A-F]{8}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{4}-[a-f0-9A-F]{12}$/,
      regexPlacaVM: /^[^(?!.*\s)-]{5,7}$/,
      regexAnioModeloVM: /^(19[0-9]{2}|20[0-9]{2})$/,
      regexNumLicencia: /^[^|]{6,16}$/,
      regexPedimento: /^[0-9]{2}  [0-9]{2}  [0-9]{4}  [0-9]{7}$/,
      regexNumRegIdTrib: /^[^|]{6,40}$/,
      regexClaveSTCC: /^[0-9|]{6,7}$/,
    }
  },
}
