export const clearValuesOnHide = {
  methods: {
    clearValuesOnHide(store, obj) {
      Object.entries(obj).forEach(([k, v]) => {
        if (typeof v === 'object') {
          this.clearValuesOnHide(store[k], v)
        }
        if (v === false) {
          store[k] = ''
        }
      })
    },
  },
}
