<template>
  <div v-if="regimen">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Regimen aduanero" label-for="RegimenAduanero">
          <validation-provider
            #default="{ errors }"
            name="Regimen aduanero"
            :vid="'RegimenAduanero_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="RegimenAduanero"
              v-model="regimen['@RegimenAduanero']"
              label="title"
              catalogo="catRegimenAduanero"
              clave="clave"
              descripcion="descripcion"
              :search-terms="EntradaSalidaMerc.value"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol, BFormInput } from 'bootstrap-vue'
import { clearValuesOnHide } from '@/mixins/clearValuesOnHide'
import { validationRules } from '@/mixins/validationRules'
import AjaxVueSelect from '@/components/AjaxVueSelect'
import { ValidationProvider } from 'vee-validate'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    AjaxVueSelect,
    BFormGroup,
    BRow,
    BCol,
  },
  mixins: [clearValuesOnHide, validationRules],
  props: {
    regimen: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields('CartaPorte', {
      EntradaSalidaMerc: 'objJson.complemento.CartaPorte.@EntradaSalidaMerc',
    }),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
