<template>
  <div>
    <b-row>
      <b-col v-if="mostrar['@RFCFigura']" cols="2">
        <b-form-group label="RFC" label-for="RFCFigura">
          <validation-provider
            #default="{ errors }"
            name="RFC en figura transporte"
            :vid="'RFCFigura_' + index"
            :rules="{
              required: true,
              regex: regexRfcMutable,
            }"
          >
            <b-form-input
              id="RFCFigura"
              v-model="datos['@RFCFigura']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="13"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@NumRegIdTribFigura']" cols="2">
        <b-form-group label="Id tributario" label-for="NumRegIdTribFigura">
          <validation-provider
            #default="{ errors }"
            name="Id tributario"
            :vid="'NumRegIdTribFigura_' + index"
            :rules="{
              required: true,
              regex: regexNumRegIdTrib,
            }"
          >
            <b-form-input
              id="NumRegIdTribFigura"
              v-model="datos['@NumRegIdTribFigura']"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="mostrar['@NumLicencia']" cols="2">
        <b-form-group label="Número de licencia" label-for="NumLicencia">
          <validation-provider
            #default="{ errors }"
            name="Número de licencia"
            :vid="'NumLicencia_' + index"
            :rules="{
              regex: regexNumLicencia,
              required: true,
            }"
          >
            <b-form-input
              id="NumLicencia"
              v-model="datos['@NumLicencia']"
              :state="errors.length > 0 ? false : null"
              maxlength="16"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Nombre" label-for="NombreFigura">
          <validation-provider
            #default="{ errors }"
            name="Nombre"
            :vid="'NombreFigura_' + index"
            :rules="{
              required: true,
            }"
          >
            <b-form-input
              id="NombreFigura"
              v-model="datos['@NombreFigura']"
              placeholder=""
              maxlength="254"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="mostrar['@ResidenciaFiscalFigura']" cols="4">
        <b-form-group
          label="Residencia fiscal"
          label-for="ResidenciaFiscalFigura"
        >
          <AjaxVueSelect
            id="ResidenciaFiscalFigura"
            v-model="datos['@ResidenciaFiscalFigura']"
            label="title"
            catalogo="catPais"
            clave="pais"
            descripcion="descripcion"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol, BFormInput } from 'bootstrap-vue'
import AjaxVueSelect from '@/components/AjaxVueSelect'
import 'flatpickr/dist/l10n/es'
import { validationRules } from '@/mixins/validationRules'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    AjaxVueSelect,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
  },
  mixins: [validationRules],
  props: {
    datos: {
      type: Object,
      default: null,
    },
    mostrar: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    regexRfcMutable: {
      type: RegExp,
      default:
        /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
