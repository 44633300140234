export const cleaveOptions = {
  data() {
    return {
      cleave_options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
        },
        number_with_comma: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 6,
        },
        number_with_comma_2d: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 2,
        },
        number_with_comma_3d: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalScale: 3,
        },
        prefix_origen: {
          numericOnly: true,
          blocks: [6],
        },
        pedimento: {
          delimiter: '  ',
          blocks: [2, 2, 4, 7],
        },
      },
    }
  },
}
