var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Transporte internacional","label-for":"TranspInternac"}},[_c('v-select',{attrs:{"id":"TranspInternac","label":"title","options":_vm.TranspInternac_options,"clearable":false},model:{value:(_vm.TranspInternac),callback:function ($$v) {_vm.TranspInternac=$$v},expression:"TranspInternac"}})],1)],1),(_vm.mostrar['EntradaSalidaMerc'])?[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Entrada o salida del territorio","label-for":"EntradaSalidaMerc"}},[_c('validation-provider',{attrs:{"name":"Entrada o salida del territorio","vid":'EntradaSalidaMerc',"rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"EntradaSalidaMerc","label":"title","options":_vm.EntradaSalidaMerc_options,"clearable":false},model:{value:(_vm.EntradaSalidaMerc),callback:function ($$v) {_vm.EntradaSalidaMerc=$$v},expression:"EntradaSalidaMerc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,890461198)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.EntradaSalidaMerc.value === 'Entrada'
              ? 'Pais origen'
              : 'Pais destino',"label-for":"PaisOrigenDestino"}},[_c('validation-provider',{attrs:{"name":"Pais","vid":'PaisOrigenDestino',"rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('AjaxVueSelect',{attrs:{"id":"PaisOrigenDestino","label":"title","catalogo":"catPais","clave":"pais","descripcion":"descripcion","clearable":false},model:{value:(_vm.PaisOrigenDestino),callback:function ($$v) {_vm.PaisOrigenDestino=$$v},expression:"PaisOrigenDestino"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3860338731)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('RegimenesAduaneros')],1)]:_vm._e()],2),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Registro ISTMO","label-for":"RegistroISTMO"}},[_c('v-select',{attrs:{"id":"RegistroISTMO","label":"title","options":_vm.RegistroISTMO_options,"clearable":false},model:{value:(_vm.RegistroISTMO),callback:function ($$v) {_vm.RegistroISTMO=$$v},expression:"RegistroISTMO"}})],1)],1),(_vm.mostrar['RegistroISTMO'])?[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Ubicacion polo origen","label-for":"UbicacionPoloOrigen"}},[_c('AjaxVueSelect',{attrs:{"id":"UbicacionPoloOrigen","label":"title","catalogo":"catRegistroISTMO","clave":"clave","descripcion":"descripcion","clearable":false},model:{value:(_vm.UbicacionPoloOrigen),callback:function ($$v) {_vm.UbicacionPoloOrigen=$$v},expression:"UbicacionPoloOrigen"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Ubicacion polo destino","label-for":"UbicacionPoloDestino"}},[_c('AjaxVueSelect',{attrs:{"id":"UbicacionPoloDestino","label":"title","catalogo":"catRegistroISTMO","clave":"clave","descripcion":"descripcion","clearable":false},model:{value:(_vm.UbicacionPoloDestino),callback:function ($$v) {_vm.UbicacionPoloDestino=$$v},expression:"UbicacionPoloDestino"}})],1)],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }