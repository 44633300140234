<template>
  <div>
    <b-row>
      <b-col cols="3">
        <b-form-group
          label="Logistica inversa de recoleccion o devolución"
          label-for="LogisticaInversaRecoleccionDevolucion"
        >
          <v-select
            id="LogisticaInversaRecoleccionDevolucion"
            v-model="LogisticaInversaRecoleccionDevolucion"
            label="title"
            :options="LogisticaInversaRecoleccionDevolucion_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div ref="form">
      <!-- Row Loop -->
      <b-row
        v-for="(mercancia, index) in Mercancias"
        :id="index"
        :key="index"
        ref="row"
      >
        <!-- Item Name -->
        <b-col cols="10">
          <Mercancia :mercancia="mercancia" :index="index" />
        </b-col>

        <!-- Remove Button -->
        <b-col cols="2">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon icon="XIcon" class="mr-25" />
            <span>Borrar</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="addItem"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Agregar mercancia</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BFormGroup } from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
import Mercancia from '@/views/CartaPorte/Mercancia'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    vSelect,
    Mercancia,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      LogisticaInversaRecoleccionDevolucion_options: [
        { title: 'Sí', value: 'Sí' },
        { title: 'No', value: '' },
      ],
      nextRowId: 0,
    }
  },
  computed: {
    ...mapMultiRowFields('CartaPorte', {
      Mercancias: 'objJson.complemento.CartaPorte.Mercancias.Mercancia',
    }),
    ...mapFields('CartaPorte', {
      LogisticaInversaRecoleccionDevolucion:
        'objJson.complemento.CartaPorte.Mercancias.@LogisticaInversaRecoleccionDevolucion',
    }),
  },
  mounted() {
    this.initTrHeight()
    this.nextRowId = this.Mercancias.length
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions('CartaPorte', ['addMercancia', 'deleteMercancia']),
    addItem() {
      this.addMercancia({
        '@BienesTransp': '',
        '@ClaveSTCC': '',
        '@Descripcion': '',
        '@Cantidad': '',
        '@ClaveUnidad': '',
        '@Unidad': '',
        '@Dimensiones': '',
        '@MaterialPeligroso': '',
        '@CveMaterialPeligroso': '',
        '@Embalaje': '',
        '@DescripEmbalaje': '',
        '@PesoEnKg': '',
        '@ValorMercancia': '',
        '@Moneda': '',
        '@FraccionArancelaria': '',
        '@UUIDComercioExt': '',
        '@SectorCOFEPRIS': '',
        '@NombreIngredienteActivo': '',
        '@NomQuimico': '',
        '@DenominacionGenericaProd': '',
        '@DenominacionDistintivaProd': '',
        '@Fabricante': '',
        '@FechaCaducidad': '',
        '@LoteMedicamento': '',
        '@FormaFarmaceutica': '',
        '@CondicionesEspTransp': '',
        '@RegistroSanitarioFolioAutorizacion': '',
        '@PermisoImportacion': '',
        '@FolioImpoVUCEM': '',
        '@NumCAS': '',
        '@RazonSocialEmpImp': '',
        '@NumRegSanPlagCOFEPRIS': '',
        '@DatosFabricante': '',
        '@DatosFormulador': '',
        '@DatosMaquilador': '',
        '@UsoAutorizado': '',
        '@TipoMateria': '',
        '@DescripcionMateria': '',
        DocumentacionAduanera: [],
        CantidadTransporta: [],
        /* TODO Falta agregar y verificar que cuando no se utilicen no agregue etiqueta de cerrado </Mercancia>
        DetalleMercancia: {
          '@UnidadPeso': '',
        },
         */
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.deleteMercancia(index)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
