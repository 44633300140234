<template>
  <div>
    <b-row>
      <b-col cols="2">
        <b-form-group label="Tipo de residencia" label-for="TipoDeResidencia">
          <v-select
            id="TipoDeResidencia"
            v-model="figura.TipoDeResidencia"
            label="title"
            :options="TipoDeResidencia_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Tipo de figura" label-for="TipoFigura">
          <v-select
            id="TipoFigura"
            v-model="figura['@TipoFigura']"
            label="title"
            :options="TipoDeFigura_options"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <DatosFigura
      :datos="figura"
      :mostrar="mostrar"
      :index="index"
      :regex-rfc-mutable="regexRFCParaFigura"
    />
    <Domicilio
      :domicilio="figura['Domicilio']"
      :tipo-remitente="figura.TipoDeResidencia.valx"
      :index="index"
    />
    <PartesTransportes
      v-if="mostrarPartesTransporte()"
      :partes="figura.PartesTransporte"
      :tipo-de-residencia="figura.TipoDeResidencia"
    />
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { clearValuesOnHide } from '@/mixins/clearValuesOnHide'
import { cleaveOptions } from '@/mixins/cleaveOptions'
import DatosFigura from '@/views/CartaPorte/DatosFigura'
import PartesTransportes from '@/views/CartaPorte/PartesTransportes'
import Domicilio from '@/views/CartaPorte/Domicilio'

export default {
  components: {
    Domicilio,
    PartesTransportes,
    DatosFigura,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
  },
  mixins: [clearValuesOnHide, cleaveOptions],
  props: {
    figura: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      TipoDeFigura_options: [
        { title: 'Operador', value: '01' },
        { title: 'Propietario', value: '02' },
        { title: 'Arrendador', value: '03' },
        { title: 'Notificado', value: '04' },
        { title: 'Integrante de Coordinados', value: '05' },
      ],
      TipoDeResidencia_options: [
        { title: 'Nacional', valx: 'nacional' },
        { title: 'Extranjero', valx: 'extranjero' },
      ],
    }
  },
  computed: {
    mostrar() {
      let campos = {
        '@RFCFigura': ['nacional'].includes(this.figura.TipoDeResidencia.valx),
        '@NumRegIdTribFigura': ['extranjero'].includes(
          this.figura.TipoDeResidencia.valx
        ),
        '@ResidenciaFiscalFigura': ['extranjero'].includes(
          this.figura.TipoDeResidencia.valx
        ),
        '@NumLicencia': ['01'].includes(this.figura['@TipoFigura'].value),
      }

      this.clearValuesOnHide(this.figura, campos)
      return campos
    },
    regexRFCParaFigura() {
      if (this.figura['@TipoFigura'].value === '01') {
        return /^(?!XAXX010101000)([A-Z&Ñ]{4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A])$/
      } else {
        return /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/
      }
    },
  },
  methods: {
    mostrarPartesTransporte() {
      if (['02', '03'].includes(this.figura['@TipoFigura'].value)) {
        return true
      } else {
        if (this.figura.PartesTransporte.length > 0) {
          this.figura.PartesTransporte = []
        }
        return false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
