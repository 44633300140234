<template>
  <div v-if="remolque">
    <b-row>
      <b-col cols="10">
        <b-form-group label="Subtipo de remolque" label-for="SubTipoRem">
          <validation-provider
            #default="{ errors }"
            name="Subtipo de remolque"
            :vid="'SubTipoRem_' + index"
            :rules="{
              required: true,
            }"
          >
            <AjaxVueSelect
              id="SubTipoRem"
              v-model="remolque['@SubTipoRem']"
              label="title"
              catalogo="catSubTipoRem"
              clave="clave"
              descripcion="descripcion"
              :value-as-object="false"
              :clearable="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Placa" label-for="Placa">
          <validation-provider
            #default="{ errors }"
            name="Placa"
            :vid="'Placa_' + index"
            :rules="{
              regex: regexPlacaVM,
              required: true,
            }"
          >
            <b-form-input
              id="Placa"
              v-model="remolque['@Placa']"
              placeholder=""
              :state="errors.length > 0 ? false : null"
              maxlength="7"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BRow, BCol, BFormInput } from 'bootstrap-vue'
import { clearValuesOnHide } from '@/mixins/clearValuesOnHide'
import { validationRules } from '@/mixins/validationRules'
import AjaxVueSelect from '@/components/AjaxVueSelect'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    AjaxVueSelect,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
  },
  mixins: [clearValuesOnHide, validationRules],
  props: {
    remolque: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  computed: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
